import 'moment/locale/es'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { isVideo } from '../../lib/files'

export default function NeedEventFeed({ event }) {
  const { t } = useTranslation()
  const { locale } = useCurrentUser()

  // Media display
  let img = {
    src:
      locale === 'es'
        ? '/images/draft-media-placeholder-ES.png'
        : '/images/draft-media-placeholder-EN.png',
    alt: t('event.media_post_thumbnail'),
  }
  if (!!event?.media_attachments?.length) {
    img = {
      src: isVideo(event?.media_attachments[0])
        ? event?.media_attachments[0].thumbnail_url
        : event?.media_attachments[0].resized_image_url ??
          event?.media_attachments[0].direct_upload_url,
      alt: t('event.media_post_video'),
    }
  }

  useEffect(() => {
    moment.locale(locale)
  }, [locale])

  return (
    <Link to={`/events/${event?.slug ?? event.id}`}>
      <Card className="m-0 shadow-none needs">
        <Row>
          <Col className="thumbnail">
            <Card.Img
              variant="top"
              className="rounded-start rounded-end-0"
              src={img.src}
              alt={img.alt}
            />
          </Col>
          <Col className="p-0">
            <Card.Body className="col p-0 card_body h-100">
              <Card.Title className="card_title">{event.title}</Card.Title>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Link>
  )
}
