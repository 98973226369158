import 'react-datepicker/dist/react-datepicker.css'
import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import moment from 'moment-timezone'
import TelephoneInput from 'react-phone-number-input/input'
import { subYears } from 'date-fns'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import useApi from '../../hooks/useApi'
import AvatarImage from './avatar-image'
import CausePicker from '../../components/CausePicker/CausePicker'
import Icon from '../../components/icon'
import Loading from '../../components/loading'
import LocationSearch from '../../components/location-search'
import PasswordInput from '../../components/password-input'
import CivWallet from '../../components/CivWallet/civ-wallet'
import {
  formatPhoneNumberForDisplay,
  formatPhoneNumberForSave,
} from '../../lib/phone'

registerLocale('es', es)
setDefaultLocale('en')

export default function AccountEditPage() {
  const { i18n, t } = useTranslation()

  const { currentUser, locale, setCurrentUser, fetchUser, hasBetaFeature } =
    useCurrentUser()
  const {
    getCurrentUser,
    updateAccount,
    updatePassword,
    updateUser,
    updateUserInformation,
    updateEmailHeaders,
    requestAccountVerification,
    sendAccountVerificationCode,
    getShirtSizes,
  } = useApi()

  const [confirmPassword, setConfirmPassword] = useState()
  const [hasUserChanged, setHasUserChanged] = useState(false)
  const [openPasswordConfirmationModal, setOpenPasswordConfirmationModal] =
    useState(false)
  const [emailPasswordConfirmation, setEmailPasswordConfirmation] = useState()

  const [isLoading, setIsLoading] = useState(false)
  const [newPassword, setNewPassword] = useState()
  const [user, setUser] = useState(() => currentUser)
  const [email, setEmail] = useState(() => currentUser.email)

  const [waitingForEmailVerification, setWaitingForEmailVerification] =
    useState(false)
  const [emailVerificationCode, setEmailVerificationCode] = useState('')
  const [emailVerificationError, setEmailVerificationError] = useState(false)
  const [emailVerificationMaxPerDay, setEmailVerificationMaxPerDay] =
    useState(false)
  const [emailVerificationMaxPerMonth, setEmailVerificationMaxPerMonth] =
    useState(false)
  const [shirtSizes, setShirtSizes] = useState()
  const nameRef = useRef(null)

  const fetchData = useCallback(async () => {
    const sizes = await getShirtSizes()
    setShirtSizes(sizes.data)
    const { data } = await getCurrentUser()
    if (data) {
      if (data.gender === null) {
        data.gender = ''
      }
      if (data.location === null) {
        data.location = ''
      }
      setUser(data)
    }
    setIsLoading(false)
  }, [getCurrentUser])

  const onLocationChange = useCallback(
    async _location => {
      setUser(user => ({
        ...user,
        location: _location?.description,
      }))
      setHasUserChanged(true)
    },
    [user],
  )

  const onReturnUrl = () => {
    if (localStorage.getItem('return-url')) {
      const url = localStorage.getItem('return-url')
      localStorage.removeItem('return-url')
      window.location = url
    }
  }

  const onEmailVerifyRequest = useCallback(async () => {
    const response = await requestAccountVerification()
    if (response.status === 200) {
      setWaitingForEmailVerification(true)
      setEmailVerificationError(false)
      setEmailVerificationCode('')
    } else {
      const { data } = response.error.response
      if (data.base && data.base[0].includes('max_per_day')) {
        setEmailVerificationMaxPerDay(true)
      } else if (data.base && data.base[0].includes('max_per_month')) {
        setEmailVerificationMaxPerMonth(true)
      }
    }
  })

  const onEmailVerifyCode = useCallback(async () => {
    const response = await sendAccountVerificationCode(emailVerificationCode)
    if (response.status === 200) {
      setWaitingForEmailVerification(false)
      onReturnUrl()
      if (localStorage.getItem('verify-email-message')) {
        localStorage.removeItem('verify-email-message')
        setCurrentUser({ ...currentUser, email_verified_at: new Date() })
        window.location = '/charities'
      } else {
        fetchData()
      }
    } else {
      setEmailVerificationError(true)
    }
  }, [emailVerificationCode, currentUser])

  const onEmailVerifyCancel = useCallback(async () => {
    setWaitingForEmailVerification(false)
    localStorage.removeItem('verify-email-message')
    onReturnUrl()
  })

  const onSaveNewPassword = useCallback(
    async e => {
      setIsLoading(true)
      e.preventDefault()
      const response = await updatePassword({
        newPassword,
        confirmPassword,
      })
      if (response.errors) {
        // eslint-disable-next-line no-alert
        alert(response.errors?.full_messages?.[0])
      } else {
        setNewPassword('')
        setConfirmPassword('')
      }
      setIsLoading(false)
    },
    [user, newPassword, confirmPassword],
  )

  const onSave = useCallback(async () => {
    setIsLoading(true)
    const params = {
      birthday: user.birthday
        ? moment(user.birthday).format('YYYY-MM-DD 12:00')
        : undefined,
      gender: user.gender,
      shirt_size_id: user.shirt_size_id,
      language: user.language,
      location: user.location,
      marketing_opt_in: user.marketing_opt_in,
      media_attachment_id: user.media_attachment_id,
      name: user.name,
      phone: user.phone ? formatPhoneNumberForSave(user.phone) : '',
    }

    const formData = new FormData()
    Object.keys(params).forEach(key => {
      if (params[key] !== undefined) {
        formData.append(key, params[key])
      }
    })

    const response = await updateAccount(formData)

    if (response.errors) {
      // eslint-disable-next-line no-alert
      alert(
        response.errors?.full_messages?.[0] ??
          userInformationResponse.errors?.full_messages?.[0],
      )
    } else {
      if (user.language) {
        i18n.changeLanguage(user.language)
        moment.locale(user.language || locale)
      }
      setHasUserChanged(false)
    }

    if (email !== user.email) {
      const userInformationParams = {
        user: {
          email,
          password: emailPasswordConfirmation,
        },
      }
      const userInformationResponse = await updateUserInformation(
        user.id,
        userInformationParams,
      )
      if (
        userInformationResponse?.success === false &&
        !(userInformationResponse?.status === 200)
      ) {
        alert(t('users.edit.email_can_not_be_updated'))
        setEmail(user.email)
        setOpenPasswordConfirmationModal(false)
        setEmailPasswordConfirmation('')
      } else {
        const newUser = {
          ...user,
          email: email,
          email_verified_at: null,
          uid: email,
        }
        setUser(newUser)
        setOpenPasswordConfirmationModal(false)
        setEmailPasswordConfirmation('')
        updateEmailHeaders(email)
      }
    }

    setIsLoading(false)
  }, [
    user,
    setCurrentUser,
    fetchData,
    emailPasswordConfirmation,
    setUser,
    updateEmailHeaders,
    user?.email,
    email,
  ])

  useEffect(() => {
    fetchData()
    if (nameRef?.current) {
      nameRef.current.focus()
      nameRef.current.blur()
    }
    if (
      localStorage.getItem('force-verify-email') ||
      localStorage.getItem('new-account')
    ) {
      localStorage.removeItem('force-verify-email')
      localStorage.removeItem('new-account')
      fetchUser().then(() => {
        if (!user?.email_verified_at) {
          onEmailVerifyRequest()
        }
      })
    }
  }, [])

  const tryToSave = () => {
    if (user.email === email) {
      onSave()
    } else {
      setOpenPasswordConfirmationModal(true)
    }
  }

  const imageUserOnChange = async mediaAttachment => {
    const formData = new FormData()
    formData.append('media_attachment_id', mediaAttachment.id)
    const response = await updateAccount(formData)
    if (response.errors) {
      // eslint-disable-next-line no-alert
      alert(response.errors?.full_messages?.[0])
    } else {
      setUser({ ...user, media_attachment: { ...mediaAttachment } })
      setHasUserChanged(true)
    }
  }

  return (
    <>
      <Loading show={isLoading} />
      <div className="container">
        <div className="row">
          <h1 className="fs-1 pt-4">{t('users.edit.profile_settings')}</h1>
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="row">
          <h1>{t('users.edit.user_profile')}</h1>
        </div>
        <div className="row">
          <div className="col">
            <AvatarImage
              imageUrl={
                user?.media_attachment?.thumbnail_url ??
                user?.media_attachment?.direct_upload_url ??
                '/images/default-user.png'
              }
              mediaAttachmentType="user"
              onChange={imageUserOnChange}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="form-group col-md-6">
            <label htmlFor="name">{t('devise.sessions.fullname')}</label>
            <input
              className="form-control"
              id="fullName"
              name="name"
              type="text"
              value={user?.name}
              onChange={e => {
                setUser({ ...user, name: e.target.value })
                setHasUserChanged(true)
              }}
              ref={nameRef}
            />
            {/* <div className="alert alert-danger hide">
                {t("users.edit.fullname_required")}
              </div> */}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="email">
              {t('devise.sessions.email')}
              {!!user?.email_verified_at ? (
                <span className="verified-account">
                  {' '}
                  <Icon name={'user-check'} /> {t('users.edit.email_verified')}
                </span>
              ) : (
                <span
                  className="btn btn-link"
                  onClick={onEmailVerifyRequest}
                  role="presentation"
                >
                  <Icon name={'envelope'} />{' '}
                  {emailVerificationError
                    ? t('users.edit.email_verify_failed')
                    : t('users.edit.email_verify_prompt')}
                </span>
              )}
            </label>
            <input
              className="form-control"
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
            {/* {devise_mapping.confirmable? && resource.pending_reconfirmation? && (
              <div>{t('users.edit.pending_confirmation')} {resource.unconfirmed_email}</div>
            )} */}
          </div>
        </div>
        <div className="row form-row">
          <div className="form-group col-md-6">
            <label htmlFor="nickname">{t('devise.sessions.username')}</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">@</div>
              </div>
              <input
                className="form-control"
                disabled
                id="nickname"
                name="nickname"
                type="text"
                value={user?.nickname}
                onChange={e => {
                  setUser({ ...user, nickname: e.target.value })
                  setHasUserChanged(true)
                }}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="phone">{t('users.edit.phone')}</label>
            <TelephoneInput
              className="form-control"
              country="US"
              initialValueFormat="national"
              international={false}
              onChange={phone => {
                setUser({ ...user, phone })
                setHasUserChanged(true)
              }}
              placeholder="(123) 456-7890"
              value={formatPhoneNumberForDisplay(user?.phone)}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="autocomplete">{t('users.edit.location')}</label>
          <LocationSearch
            onChange={onLocationChange}
            placeholder={t('users.edit.search')}
            value={user?.location}
          />
          <br />
        </div>
        <div className="row form-row">
          <div className="col-md-6 form-group">
            <label htmlFor="birthDate">{t('users.edit.birthday')}</label>
            <DatePicker
              className="form-control"
              dateFormat={t('config.date_picker')}
              id="birthDate"
              maxDate={subYears(new Date(), 13)}
              name="birthday"
              placeholderText={t('users.edit.no_birthday')}
              selected={user?.birthday ? moment(user?.birthday).toDate() : null}
              shouldCloseOnSelect
              wrapperClassName="form-control"
              onChange={date => {
                setUser({
                  ...user,
                  birthday: date ? moment(date).format('YYYY-MM-DD') : null,
                })
                setHasUserChanged(true)
              }}
            />
          </div>
          <div className="col-md-6 form-group">
            <label htmlFor="tShirt">{t('users.edit.t_shirt')}</label>
            <select
              name="T-Shirt"
              id="tShirt"
              className="form-control"
              onChange={({ target }) => {
                setUser({ ...user, shirt_size_id: target.value })
                setHasUserChanged(true)
              }}
            >
              <option selected={!user.shirt_size} value="">
                {t('users.edit.no_gender')}
              </option>
              {shirtSizes ? (
                shirtSizes.map(shirtSize => {
                  return (
                    <option
                      key={shirtSize.id}
                      selected={user.shirt_size?.id === shirtSize.id}
                      value={shirtSize.id}
                    >
                      {shirtSize[`${locale}_name`]}
                    </option>
                  )
                })
              ) : (
                <option disabled>loading...</option>
              )}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="form-group col-md-6">
            <label htmlFor="gender">{t('users.edit.gender')}</label>
            <select
              name="gender"
              id="gender"
              className="form-control"
              onChange={e => {
                setUser({ ...user, gender: e.target.value })
                setHasUserChanged(true)
              }}
            >
              <option selected={!user?.gender || user?.gender === ''} value="">
                {t('users.edit.no_gender')}
              </option>
              <option
                selected={user?.gender === 'Male' || user?.gender === 'Hombre'}
                value="Male"
              >
                {t('users.edit.male')}
              </option>
              <option
                selected={user?.gender === 'Female' || user?.gender === 'Mujer'}
                value="Female"
              >
                {t('users.edit.female')}
              </option>
              <option
                selected={user?.gender === 'Other' || user?.gender === 'Otro'}
                value="Other"
              >
                {t('users.edit.other_gender')}
              </option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="language">{t('users.edit.language')}</label>
            <select
              name="language"
              id="language"
              className="form-control"
              onChange={e => {
                setUser({ ...user, language: e.target.value })
                setHasUserChanged(true)
              }}
            >
              <option selected={user?.language === 'en'} value="en">
                {t('users.edit.english')}
              </option>
              <option selected={user?.language === 'es'} value="es">
                {t('users.edit.spanish')}
              </option>
            </select>
          </div>
        </div>
        <input
          className="btn btn-primary"
          id="settings-form-submit"
          onClick={tryToSave}
          type="submit"
          value={t('users.edit.save')}
          disabled={!hasUserChanged && email === user.email}
        />
      </div>
      <hr />

      <CausePicker />
      <hr />
      {hasBetaFeature('civ_wallet') ? (
        <>
          <CivWallet
            data={user}
            setData={setUser}
            locale={locale}
            updateData={updateUser}
            origin={'user'}
          />
          <hr />
        </>
      ) : null}

      <div className="container">
        <div className="row">
          <h1>
            <Icon name="lock-alt" className="business-title-icon" />
            {t('users.edit.update_pw')}
          </h1>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="inputNewPassword">{t('users.edit.new_pw')}</label>
            <PasswordInput
              onChange={val => setNewPassword(val)}
              showPasswordToggle
              value={newPassword || ''}
              autofocus={false}
            />
            <small id="newPasswordHelp" className="form-text text-muted">
              {t('users.edit.pw_requirements')} _!@%&#x26;()*&#x27;&#x27;.
            </small>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="confirmPassword">
              {t('users.edit.confirm_pw')}
            </label>
            <PasswordInput
              onChange={val => setConfirmPassword(val)}
              showPasswordToggle
              value={confirmPassword || ''}
              autofocus={false}
            />
          </div>
        </div>
        <button
          className="btn btn-primary"
          onClick={onSaveNewPassword}
          style={{ marginBottom: '50px' }}
          type="submit"
          disabled={
            !newPassword ||
            !confirmPassword ||
            newPassword !== confirmPassword ||
            newPassword.length < 6
          }
        >
          {t('users.edit.save')}
        </button>
      </div>

      <Modal show={waitingForEmailVerification} onHide={onEmailVerifyCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{t('users.edit.email_verify_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {t('users.edit.email_verify_message', { email: user.email })}
          </div>
          <br />
          {emailVerificationError && (
            <Alert
              variant="danger"
              onClose={() => setEmailVerificationError(false)}
              dismissible
            >
              <p>{t('users.edit.email_verify_error_alert')}</p>
            </Alert>
          )}
          {localStorage.getItem('verify-email-message') && (
            <Alert
              variant="warning"
              onClose={() => localStorage.removeItem('verify-email-message')}
              dismissible
            >
              <p>{localStorage.getItem('verify-email-message')}</p>
            </Alert>
          )}
          <input
            className="form-control"
            inputMode="numeric"
            type="text"
            placeholder={t('users.edit.email_verify_placeholder')}
            onChange={e => setEmailVerificationCode(e.target.value)}
            value={emailVerificationCode}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onEmailVerifyCancel}>
            {t('event.delete_modal_cancel')}
          </Button>
          <Button variant="primary" onClick={onEmailVerifyCode}>
            {t('users.edit.email_verify')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openPasswordConfirmationModal}
        onHide={() => setOpenPasswordConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t('users.edit.email_verify_password_title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {t('users.edit.email_verify_password_description', {
              email: user.email,
            })}
          </div>
          <br />
          <label htmlFor="emailPasswordConfirmation">
            {t('users.edit.password_confirmation')}
          </label>
          <PasswordInput
            className="form-control"
            name={'emailPasswordConfirmation'}
            id={'emailPasswordConfirmation'}
            type="text"
            onChange={value => setEmailPasswordConfirmation(value)}
            value={emailPasswordConfirmation}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => onSave()}
            disabled={!emailPasswordConfirmation}
          >
            {t('users.edit.save')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => setOpenPasswordConfirmationModal(false)}
          >
            {t('event_card.close')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={emailVerificationMaxPerDay}
        onHide={() => setEmailVerificationMaxPerDay(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('users.edit.email_verify_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <p>{t('users.edit.email_verify_max_day')}</p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEmailVerificationMaxPerDay(false)}
          >
            {t('event_card.close')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={emailVerificationMaxPerMonth}
        onHide={() => setEmailVerificationMaxPerMonth(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('users.edit.email_verify_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <p>{t('users.edit.email_verify_max_month')}</p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setEmailVerificationMaxPerMonth(false)}
          >
            {t('event_card.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
