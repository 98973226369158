import React, { useCallback, useEffect, useState } from 'react'
import { Buffer } from 'buffer'
import { ProgressBar } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import heic2any from 'heic2any'
import PropTypes from 'prop-types'

import { isImage, isVideo, isPDF } from '../../lib/files'
import Icon from '../icon'
import PDFViewer from '../../components/PDFViewer'
import Styles from './uploader.module.scss'
import upload from '../../constants/upload'
import useApi from '../../hooks/useApi'
import VideoThumbnail from '../video-thumbnail'

const isTabletOrMobile = window.matchMedia('(max-width: 1000px)').matches

export default function Uploader(props) {
  const {
    accept,
    disabled,
    extraAttachmentData,
    id,
    mediaAttachments: initialMediaAttachments,
    multiple,
    onUploadComplete,
    onUploadStart,
    onRemove,
    renderButton,
    type,
  } = props

  const {
    createMediaAttachment,
    getMediaAttachmentUploadSignedUrl,
    getPrivateAttachmentUploadSignedUrl,
    uploadFileToS3,
  } = useApi()

  const [isUploading, setIsUploading] = useState(false)

  const attachmentReducer = (state, action) => {
    const newState = [...state]
    if (Array.isArray(action)) {
      action.forEach(a => {
        const attachmentIdx = state.findIndex(
          attachment =>
            attachment.uploadId === a.uploadId || attachment.id === a.id,
        )

        if (attachmentIdx >= 0) {
          if (a.delete) {
            newState.splice(attachmentIdx, 1)
          } else {
            newState[attachmentIdx] = a
          }
        } else {
          newState.push(a)
        }
      })
    } else {
      const attachmentIdx = state.findIndex(
        attachment =>
          attachment.uploadId === action.uploadId ||
          attachment.id === action.id,
      )
      if (attachmentIdx >= 0) {
        if (action.delete) {
          newState.splice(attachmentIdx, 1)
        } else {
          newState[attachmentIdx] = action
        }
      }
    }
    return newState
  }

  const [mediaAttachments, setMediaAttachments] = React.useReducer(
    attachmentReducer,
    initialMediaAttachments || [],
  )

  const getAttachmentUploadSignedUrl =
    type === 'private'
      ? getPrivateAttachmentUploadSignedUrl
      : getMediaAttachmentUploadSignedUrl

  const convertWebpToJpeg = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = e => {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = img.width
          canvas.height = img.height
          ctx.drawImage(img, 0, 0)

          canvas.toBlob(
            blob => {
              const convertedFile = new File(
                [blob],
                file.name.replace('.webp', '.jpg'),
                {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                },
              )
              resolve(convertedFile)
            },
            'image/jpeg',
            1,
          )
        }
        img.src = e.target.result
      }

      reader.onerror = error => {
        reject(error)
      }

      if (file) {
        reader.readAsDataURL(file)
      } else {
        reject(new Error('No se proporcionó ningún archivo.'))
      }
    })
  }

  const handleFilesChanged = useCallback(
    async e => {
      if (e.target.files.length === 0) {
        return
      }
      // if multiple is false, only use the first file
      if (e.target.files.length > 1 && !multiple) {
        e.target.files = [e.target.files[0]]
      }

      setIsUploading(true)

      const fileUploads = Array.from(e.target?.files).map(
        file =>
          new Promise(async (resolve, reject) => {
            if (file.size >= upload.sizeLimit) {
              alert('File size must be less than 120MB')
              reject()
              return
            }

            // convert heic to jpeg
            if (file.type === 'image/heic') {
              const blob = await heic2any({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.5,
              })
              const newName = file.name.toLowerCase().replace(/\.heic$/, '.jpg')
              file = new File([blob], newName, { type: 'image/jpeg' })
            }

            // Convert webp to jpeg
            if (file.type === 'image/webp') {
              file = await convertWebpToJpeg(file)
            }

            if (onUploadStart) {
              onUploadStart(file)
            }
            const reader = new FileReader()
            reader.onload = loaded => {
              const data = {
                fileObject: file,
                originalFile: Buffer.from(
                  loaded.target.result.replace(/^data:image\/\w+;base64,/, ''),
                  'base64',
                ),
                direct_upload_url: loaded.target.result,
                uploading: true,
                uploadId:
                  performance.now().toString(36) +
                  Math.random().toString(36).substr(2),
                upload_content_type: file.type,
                upload_file_name: file.name,
                upload_file_size: file.size,
                media_attachment_type: 'event', // default type
                ...extraAttachmentData,
              }
              resolve(data)
            }
            reader.readAsDataURL(file)
          }),
      )
      const files = await Promise.all(fileUploads)
      setMediaAttachments(files)
      for (const file of files) {
        const signedUrlResponse = await getAttachmentUploadSignedUrl({
          filename: file.upload_file_name,
        })
        const { data } = signedUrlResponse
        const uploadResponse = await uploadFileToS3(
          data.upload.url,
          file.fileObject,
          data.upload.content_type,
        )
        if (uploadResponse.status === 200) {
          const uploadData = { ...file }
          delete uploadData.uploading
          delete uploadData.uploadId
          delete uploadData.originalFile
          ;[uploadData.direct_upload_url] = data.upload.url?.split('?') ?? ''

          const createResponse = await createMediaAttachment({
            media_attachment: uploadData,
          })

          if (createResponse.status === 201) {
            onUploadComplete?.({
              ...file,
              ...createResponse.data,
              uploading: false,
            })
          }
        }
      }
      setIsUploading(false)
    },
    [extraAttachmentData, onUploadComplete],
  )

  const onDrop = useCallback(
    acceptedFiles => {
      const busy = isUploading || disabled
      if (!busy) {
        handleFilesChanged({ target: { files: acceptedFiles } })
      }
    },
    [disabled, handleFilesChanged, isUploading],
  )

  const onClickRemove = useCallback(
    id => {
      if (onRemove) {
        setMediaAttachments({ id, delete: true })
        onRemove(id)
      }
    },
    [onRemove],
  )

  useEffect(() => {
    if (initialMediaAttachments) {
      setMediaAttachments(initialMediaAttachments)
    }
  }, [initialMediaAttachments])

  const acceptTypes = {}
  accept.split(',').forEach(t => {
    acceptTypes[t] = []
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptTypes,
    onDropAccepted: onDrop,
  })

  const renderThumbnail = mediaAttachment => {
    if (!mediaAttachment) {
      return null
    }
    if (isVideo(mediaAttachment)) {
      return (
        <VideoThumbnail
          file={mediaAttachment?.fileObject}
          thumb={mediaAttachment.thumbnail_url}
        />
      )
    }
    if (isPDF(mediaAttachment)) {
      return (
        <div className={Styles.pdfContainer}>
          <PDFViewer file={mediaAttachment?.fileObject} width={120} />
        </div>
      )
    }
    const src =
      mediaAttachment.thumbnail_url || mediaAttachment.direct_upload_url
    if (isImage(mediaAttachment)) {
      return <img alt="thumbnail" src={src} className={Styles.image} />
    }
    return null
  }

  return (
    <div className={Styles.container}>
      {mediaAttachments?.map((mediaAttachment, index) => {
        if (!mediaAttachment) {
          return null
        }
        if (mediaAttachment.delete) {
          return null
        }
        return (
          <span
            className={Styles.thumbnail}
            key={mediaAttachment.upload_file_name}
          >
            <div className={Styles.imageContainer}>
              <button
                type="button"
                className={Styles.remove}
                onClick={() => onClickRemove(mediaAttachment.id)}
              >
                <Icon name="times" />
              </button>
              {renderThumbnail(mediaAttachment)}
              {mediaAttachment.uploading && (
                <div className={Styles.progress}>
                  <ProgressBar animated now={100} />
                </div>
              )}
            </div>
          </span>
        )
      })}

      <label
        disabled={isUploading || disabled}
        className={Styles.uploader}
        htmlFor="uploader"
        {...getRootProps({
          role: 'button',
          'aria-label': 'drag and drop area',
        })}
      >
        {renderButton}
        {isTabletOrMobile ? (
          <input
            style={{ display: 'none' }}
            accept={accept}
            disabled={isUploading || disabled}
            id={id}
            multiple={multiple}
            onChange={handleFilesChanged}
            type="file"
            name="file"
          />
        ) : (
          <input
            {...getInputProps()}
            accept={accept}
            disabled={isUploading || disabled}
            id={id}
            multiple={multiple}
            onChange={handleFilesChanged}
            type="file"
            name="file"
          />
        )}
      </label>
    </div>
  )
}

Uploader.defaultProps = {
  accept: 'video/*,image/*',
  disabled: false,
  extraAttachmentData: {},
  id: 'images',
  multiple: true,
  renderButton: <button>Upload</button>,
}

Uploader.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  extraAttachmentData: PropTypes.shape({
    media_attachment_type: PropTypes.string,
  }),
  id: PropTypes.string,
  multiple: PropTypes.bool,
  onUploadComplete: PropTypes.func.isRequired,
  onUploadStart: PropTypes.func,
  onRemove: PropTypes.func,
  renderButton: PropTypes.element,
  type: PropTypes.oneOf(['private', 'media']).isRequired,
}
