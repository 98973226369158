import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import MentionList from './MentionList.jsx'

const getFilteredOptions = async (getOptions, { query }) => {
  if (!!query && query.length && query.length > 2) {
    const items = await getOptions({ search: query.toLowerCase() })
    return items.filter(item =>
      item.name.toLowerCase().startsWith(query.toLowerCase()),
    )
  }

  return []
}

export default function createMentionRenderer(getOptions) {
  return {
    items: ({ query }) => getFilteredOptions(getOptions, { query }),

    render: () => {
      let component
      let popup
      let currentItems = []

      return {
        onStart: async props => {
          currentItems = await getFilteredOptions(getOptions, {
            query: props.query.toLowerCase(),
          })
          component = new ReactRenderer(MentionList, {
            props: { ...props, items: currentItems },
            editor: props.editor,
          })

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate: async props => {
          currentItems = await getFilteredOptions(getOptions, {
            query: props.query.toLowerCase(),
          })
          component.updateProps({ ...props, items: currentItems })

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup[0].hide()
            return true
          }
          return component.ref?.onKeyDown(props)
        },

        onExit() {
          popup[0].destroy()
          component.destroy()
        },
      }
    },
  }
}
