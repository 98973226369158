import React, { useRef, useState } from 'react'
import c from 'classnames'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import Link from '@tiptap/extension-link'
import createMentionRenderer from './createMentionRenderer'
import styles from './textareaEditor.module.scss'
import Button from '../button'
import Icon from '../icon'
import { Popover, OverlayTrigger, Form } from 'react-bootstrap'
import emoji from 'emojilib'
import { useTranslation } from 'react-i18next'

const TextareaEditor = ({ getOptions = undefined, text, onChangeText }) => {
  const { t } = useTranslation()
  const emojiRef = useRef()
  const linkRef = useRef()
  const editor = useEditor({
    editorProps: {
      attributes: {
        class: c(styles.input, 'form-control text-editor'),
      },
    },
    onUpdate: ed => onChangeText(ed.editor.getHTML()),
    extensions: [
      StarterKit,
      Bold,
      Italic,
      Underline,
      BulletList,
      OrderedList,
      Link.configure({
        HTMLAttributes: {
          target: '',
        },
      }),
      getOptions
        ? Mention.configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion: createMentionRenderer(getOptions),
          })
        : undefined,
    ],
    content: text,
  })

  const [showPopover, setShowPopover] = useState(false)
  const [showLinkPopover, setShowLinkPopover] = useState(false)
  const [linkText, setLinkText] = useState('')
  const [linkUrl, setLinkUrl] = useState('')
  const emojis = Object.keys(emoji)

  const handleInsertLink = () => {
    if (linkText && linkUrl) {
      editor
        .chain()
        .focus()
        .insertContent(`<a href="${linkUrl}">${linkText}</a>`)
        .run()
      setShowLinkPopover(false)
      setLinkText('')
      setLinkUrl('')
    }
  }

  const linkPopover = (
    <Popover id="popover-link" className={styles.linkPopover}>
      <Popover.Body className={styles.linkContainer}>
        <Form>
          <Form.Group className="mb-2">
            <Form.Label>{t('insertLink.urlTextLabel')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('insertLink.urlTextPlaceholder')}
              value={linkText}
              onChange={e => setLinkText(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>{t('insertLink.url')}</Form.Label>
            <Form.Control
              type="url"
              placeholder="https://example.com"
              value={linkUrl}
              onChange={e => setLinkUrl(e.target.value)}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={handleInsertLink}
            disabled={!linkText || !linkUrl}
            className="me-2"
          >
            {t('buttons.done')}
          </Button>
        </Form>
      </Popover.Body>
    </Popover>
  )

  const emojiPopover = (
    <Popover id="popover-basic" className={styles.emojiPopover}>
      <Popover.Body className={styles.emojiPicker}>
        {emojis.map((emoji, index) => (
          <div
            key={index}
            className={styles.emoji}
            onClick={() => {
              editor.chain().focus().insertContent(emoji).run()
              setShowPopover(false)
            }}
          >
            {emoji}
          </div>
        ))}
      </Popover.Body>
    </Popover>
  )

  if (!editor) {
    return null
  }

  return (
    <div>
      <div className={styles.menuBar}>
        <Button
          variant={editor.isActive('bold') ? 'primary' : 'secondary'}
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={c(styles.tool, editor.isActive('bold') && styles.isActive)}
        >
          <Icon name="bold" />
        </Button>
        <Button
          variant={editor.isActive('italic') ? 'primary' : 'secondary'}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={c(
            styles.tool,
            editor.isActive('italic') && styles.isActive,
          )}
        >
          <Icon name="italic" />
        </Button>
        <Button
          variant={editor.isActive('underline') ? 'primary' : 'secondary'}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={c(
            styles.tool,
            editor.isActive('underline') && styles.isActive,
          )}
        >
          <Icon name="underline" />
        </Button>
        <Button
          variant={editor.isActive('bulletList') ? 'primary' : 'secondary'}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={c(
            styles.tool,
            editor.isActive('bulletList') && styles.isActive,
          )}
        >
          <Icon name="listUl" />
        </Button>
        <Button
          variant={editor.isActive('orderedList') ? 'primary' : 'secondary'}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={c(
            styles.tool,
            editor.isActive('orderedList') && styles.isActive,
          )}
        >
          <Icon name="listOl" />
        </Button>
        <div ref={linkRef} className={styles.popoverContainer}>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            show={showLinkPopover}
            rootClose
            overlay={linkPopover}
            container={linkRef}
            onToggle={() => setShowLinkPopover(!showLinkPopover)}
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                  },
                },
              ],
            }}
          >
            <Button
              variant={editor.isActive('link') ? 'primary' : 'secondary'}
              className={c(
                styles.tool,
                editor.isActive('link') && styles.isActive,
              )}
            >
              <Icon name="link" />
            </Button>
          </OverlayTrigger>
        </div>
        <div ref={emojiRef} className={styles.popoverContainer}>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            show={showPopover}
            rootClose
            overlay={emojiPopover}
            container={emojiRef}
            onToggle={() => setShowPopover(!showPopover)}
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    boundary: 'viewport',
                  },
                },
              ],
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setShowPopover(!showPopover)}
              className={styles.tool}
            >
              <Icon name="smile" />
            </Button>
          </OverlayTrigger>
        </div>
      </div>

      <EditorContent value={text} editor={editor} />
    </div>
  )
}

export default TextareaEditor
