import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const BUILD_NUMBER = process.env.REACT_APP_CODEBUILD_BUILD_NUMBER
const VERSION_NUMBER = process.env.REACT_APP_VERSION

export default function useApi() {
  const [authHeaders, setAuthHeaders] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('authHeaders'))
    } catch (e) {
      return {}
    }
  })
  const [api] = useState(() =>
    axios.create({
      baseURL: API_BASE_URL,
      headers: authHeaders,
    }),
  )
  const [currentUser, setCurrentUser] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('currentUser'))
    } catch (e) {
      return {}
    }
  })

  useEffect(() => {
    let headers
    try {
      headers = JSON.parse(localStorage.getItem('authHeaders'))
    } catch (e) {
      headers = {}
    }
    setAuthHeaders(headers)
  }, [])

  useEffect(() => {
    api.defaults.headers = { ...api.defaults.headers, ...authHeaders }
    if (authHeaders) {
      localStorage.setItem('authHeaders', JSON.stringify(authHeaders))
    }
  }, [authHeaders])

  useEffect(() => {
    if (currentUser) {
      localStorage.setItem('currentUser', JSON.stringify(currentUser))
    }
  }, [currentUser])

  const get = useCallback(
    url =>
      api.get(url).then(response => {
        // eslint-disable-next-line no-console
        console.log('API GET', url, response)
        return response
      }),
    [api],
  )
  const post = (url, data, config) =>
    api
      .post(url, data, config)
      .catch(error => ({
        success: false,
        error,
        errors: error?.response?.data?.errors,
      }))
      .then(response => {
        // eslint-disable-next-line no-console
        console.log('API POST', url, response)
        return response
      })
  const put = (url, data, config) =>
    api
      .put(url, data, config)
      .catch(error => ({
        success: false,
        error,
        errors: error?.response?.data?.errors,
      }))
      .then(response => {
        // eslint-disable-next-line no-console
        console.log('API PUT', url, response)
        return response
      })
  const patch = (url, data, config) =>
    api
      .patch(url, data, config)
      .catch(error => ({
        success: false,
        error,
        errors: error?.response?.data?.errors,
      }))
      .then(response => {
        // eslint-disable-next-line no-console
        console.log('API PATCH', url, response)
        return response
      })
  const del = (url, data) =>
    api.delete(url, data).then(response => {
      // eslint-disable-next-line no-console
      console.log('API DELETE', url, response)
      return response
    })

  const getUser = useCallback(
    ({ nickname, userId }) => {
      if (nickname) {
        return get(
          `users/nickname/?nickname=${encodeURIComponent(
            nickname.replace('@', ''),
          )}.json`,
        )
      }
      return get(`users/${userId}.json`)
    },
    [get],
  )

  const getCurrentUser = useCallback(
    () => getUser({ userId: currentUser?.id }),
    [currentUser, getUser],
  )

  const isSessionValid = useCallback(async () => {
    let valid = false
    if (currentUser?.id) {
      const response = await getCurrentUser()
      if (response.status === 200) {
        setCurrentUser(response.data)
        valid = true
      } else {
        valid = false
      }
    }
    return valid
  }, [api, currentUser, getCurrentUser])

  const signInWithEmail = useCallback(
    async ({ email, password, locale }) =>
      post(`api/v1/auth/sign_in?locale=${locale}`, {
        email,
        password,
        web_app_build_id: BUILD_NUMBER,
        web_app_version_id: VERSION_NUMBER,
      }).then(async response => {
        if (response.status === 200) {
          if (response.headers && response.headers['access-token']) {
            const headers = {
              'access-token': response.headers['access-token'],
              'token-type': 'Bearer',
              client: response.headers.client,
              expiry: response.headers.expiry,
              uid: response.headers.uid,
            }
            setAuthHeaders(headers)
            // const userResponse = await getUser({
            //   userId: response.data?.data?.id,
            // })
            // if (userResponse.status === 200) {
            //   setCurrentUser(userResponse.data)
            // }
            setCurrentUser(response.data.data)
          }
        }
        return response
      }),
    [],
  )

  const signOut = useCallback(() => {
    setAuthHeaders({})
    setCurrentUser({})
  }, [])

  const signInWithFacebookResponse = useCallback(async response => {
    const { token, user } = response.data
    const headers = {
      'access-token': token['access-token'],
      'token-type': 'Bearer',
      client: token.client,
      expiry: token.expiry,
      uid: token.uid,
    }
    setAuthHeaders(headers)
    setCurrentUser(user)
    return response
  }, [])

  const getEventCategories = () => get('categories.json')

  const getPossibleAdmins = ({ groups, query = '', page = 1 }) => {
    // If groups are provided, the search will only return
    // users from those groups. Otherwise, it will return all users
    // A private event must search within only its groups.
    let groupString = ''
    if (groups && groups.length > 0) {
      groupString = groups.map(group => `&group_ids[]=${group.id}`)
    }
    return get(
      `users/possible_admins.json?${groupString}&page=${page}&q=${query}`,
    )
  }

  const getUserSuggestions = ({ search = '' }) =>
    api
      .get(`users/autocomplete.json`, { params: { q: search ?? '' } })
      .then(response => {
        // eslint-disable-next-line no-console
        console.log(response.data)
        return response.data.users
      })

  const sendBlockedReason = ({ id }) => put(`user_blocks/${id}`)

  const getVersions = () => get('app_versions')

  const getGroupImpact = ({ groupId }) => get(`groups/${groupId}/dashboard`)

  const getGroupActivities = () => get('dashboard/group_activities')

  const getTodaysEvents = () => get('dashboard/today.json')

  const getMonthlyEvents = () => get('dashboard/this_month.json')

  const getCurrentUserGroupEvents = useCallback(
    () => get(`users/${currentUser.id}/group_events.json`),
    [get, currentUser],
  )

  const getCurrentUserFollowingEvents = useCallback(
    () => get(`users/${currentUser.id}/following_users_events.json`),
    [get, currentUser],
  )

  const getSponsorshipOpportunities = () =>
    get('events/sponsorship_opportunities.json')

  const updateUser = (id, user) => put(`users/${id}.json`, user)

  const updateUserEmail = ({ id, user }) => put(`users/${id}`, { user })

  const updateUserQuote = ({ id, user }) => put(`users/${id}`, { user })

  const emailAvailable = ({ email }) =>
    get(`email_available?email=${encodeURIComponent(email)}`)

  const getAssociations = () => get('groups.json')

  const getSearchedAssociations = () => get('groups.json')

  const joinAssociation = ({ id }) => put(`groups/${id}/join`)

  const unjoinAssociation = ({ id }) => put(`groups/${id}/unjoin`)

  const getGroup = ({ id }) => get(`groups/${id}.json`)

  const getGroupEvents = ({ id, page, past }) =>
    get(`groups/${id}/events.json?page=${page}&past=${past}`)

  const getGroupEngagementsPDFData = ({ id }) =>
    get(`groups/${id}/pdf_events.json`)

  const getGroupMembers = ({ id, page, q }) =>
    get(`groups/${id}/members`, { page, q })

  const createGroup = () => post('groups')

  const updateGroup = ({ id }) => put(`groups/${id}`)

  const deleteGroup = ({ id }) => del(`groups/${id}`)

  const addEventAdmin = ({ eventId, userId }) =>
    put(`events/${eventId}/add_admin.json`, { user_id: userId })

  const removeEventAdmin = ({ eventId, userId }) =>
    put(`events/${eventId}/remove_admin.json`, { user_id: userId })

  const addGroupAdmin = ({ id }) => put(`groups/${id}/add_admin`)

  const removeGroupAdmin = ({ id }) => put(`groups/${id}/remove_admin`)

  const requestEventAdminAccess = ({ eventId }) =>
    put(`events/${eventId}/request_admin_access.json`)

  const blockUserFromGroup = ({ id, userId }) =>
    put(`groups/${id}/block`, { user_id: userId })
  const unblockUserFromGroup = ({ id, userId }) =>
    put(`groups/${id}/unblock`, { user_id: userId })
  const acceptUserFromGroup = ({ id, userId }) =>
    put(`groups/${id}/accept_waitlist`, { user_id: userId })
  const declineUserFromGroup = ({ id, userId }) =>
    put(`groups/${id}/decline_waitlist`, { user_id: userId })

  const search = ({ q }) => get('search.json', { q })

  const volunteerUserRating = ({ eventId, volunteerId }) =>
    put(`events/${eventId}/volunteers/${volunteerId}/rate_user`)

  const volunteerEventRating = ({ eventId, volunteerId }) =>
    put(`events/${eventId}/volunteers/${volunteerId}/rate_event`)

  const participantEventRating = ({ eventId, participantId }) =>
    put(`events/${eventId}/participants/${participantId}/rate_event`)

  const getUserEvents = ({ id }) => get(`users/${id}/events`)

  const getUserVolunteeredEvents = ({ id }) =>
    get(`users/${id}/volunteering_events`)

  const getUserEngagementEvents = ({ id }, past = false) => {
    if (past) return get(`users/${id}/engagement_events.json?past=true`)
    return get(`users/${id}/engagement_events.json`)
  }

  const getUserParticipatingEventsReadyToCheckin = ({ id }) =>
    get(`users/${id}/participating_events_ready_to_checkin`)

  const getUserFollowers = ({ id }) => get(`users/${id}/followers`)

  const getUserFollowing = ({ id }) => get(`users/${id}/following`)

  const getUserBlocked = () => get('users/blocked')

  const userFollow = ({ id }) => put(`users/${id}/follow`)

  const userUnfollow = ({ id }) => put(`users/${id}/unfollow`)

  const userBlock = ({ id }) => put(`users/${id}/block`)

  const userUnblock = ({ id }) => put(`users/${id}/unblock`)

  const submitReport = () => post('reports')

  const deletePhoto = ({ id }) => del(`media_attachments/${id}`)

  const eventLike = ({ id }) => put(`events/${id}/like`)

  const eventUnlike = ({ id }) => put(`events/${id}/unlike`)

  const galleryLike = ({ id }) => put(`media_attachments/${id}/like`)

  const galleryUnlike = ({ id }) => put(`media_attachments/${id}/unlike`)

  const commentLike = ({ id }) => put(`comments/${id}/like`)

  const commentUnlike = ({ id }) => put(`comments/${id}/unlike`)

  const eventSave = ({ id }) => put(`events/${id}/save`)

  const eventUnsave = ({ id }) => put(`events/${id}/unsave`)

  const eventSubscribeToCalendar = ({ id }) =>
    put(`events/${id}/calendar_subscribe`)

  const eventUnsubscribeFromCalendar = ({ id }) =>
    put(`events/${id}/calendar_unsubscribe`)

  const eventShare = ({ id }) => put(`events/${id}/share_count`)

  const eventUnparticipate = ({ id }) => put(`events/${id}/unparticipate.json`)

  const eventParticipate = ({ id }) => put(`events/${id}/participate.json`)

  const getNotifications = () => get('notifications')

  const giveLikePlus = ({ id }) => put(`users/${id}/likeplus`)

  const getUnreadNotificationCount = ({ id }) =>
    get(`users/${id}/unread_notifications_count`)

  const createMediaAttachment = data => post('media_attachments.json', data)

  const forgotPasswordCode = () => get('api/v1/auth/password/edit')

  const updatePassword = ({
    newPassword,
    confirmPassword,
    resetPasswordToken,
  }) =>
    put('api/v1/auth/password.json', {
      password: newPassword,
      password_confirmation: confirmPassword,
      reset_password_token: resetPasswordToken,
    })

  const eventComment = () => post('comments')

  const editComment = ({ comment }) => put(`comments/${comment.id}`, comment)

  const deleteComment = ({ id }) => del(`comments/${id}`)

  const createEvent = props => post('events.json', props)

  const getEvents = () => get('events/index_min.json')

  const deleteEvent = id => del(`events/${id}`)

  const getCurrentUserEvents = useCallback(
    () => get(`users/${currentUser.slug ?? currentUser.id}/events.json`),
    [get, currentUser],
  )

  const getCurrentUserGroups = useCallback(
    () => get(`users/${currentUser.slug ?? currentUser.id}/groups.json`),
    [get, currentUser],
  )

  const getSavedEvents = () => get('events/saved.json')

  const getEventsWithParams = params =>
    get(
      `events/index_min.json?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const getSearchedEvents = () => get('events/index_min.json')

  const getEvent = ({ id }) => get(`events/${id}.json`)

  const updateEvent = (eventId, formData) =>
    put(`events/${eventId}.json`, formData)

  const takeEvent = props => put(`events/${props.id}/take`, props)

  const getHashtag = ({ description }) =>
    get(`events/hashtag/${description}.json`)

  const createAccount = props =>
    post(`api/v1/auth?locale=${props.language}`, {
      device_id: props.deviceId,
      email: props.email,
      language: props.language,
      marketing_opt_in: props.marketing_opt_in,
      name: props.name,
      nickname: props.nickname,
      password_confirmation: props.password,
      password: props.password,
      web_app_build_id: BUILD_NUMBER,
      web_app_version_id: VERSION_NUMBER,
    }).then(response => {
      if (response.ok) {
        if (response.headers && response.headers['access-token']) {
          const authorization = {
            'access-token': response.headers['access-token'],
            'token-type': 'Bearer',
            client: response.headers.client,
            expiry: response.headers.expiry,
            uid: response.headers.uid,
          }
          setAuthHeaders(authorization)
          setCurrentUser(response.data.data)
        }
      }
      return response
    })

  const updateAccount = props => put('api/v1/auth.json', props)

  const forgotPassword = ({ email, locale }) => {
    const body = {
      email,
      redirect_url: 'https://civicrush.com',
    }
    return post(`api/v1/auth/password?locale=${locale}`, body)
  }

  const getMediaAttachmentUploadSignedUrl = data => post('uploads.json', data)

  const getPrivateAttachmentUploadSignedUrl = data => {
    return getMediaAttachmentUploadSignedUrl({
      ...data,
      private: true,
    })
  }

  const uploadFileToS3 = (url, file, contentType) =>
    put(url, file, {
      headers: {
        'Content-Type': contentType,
      },
    })

  const volunteerGet = ({ id }) => get(`events/${id}/volunteers`)
  const volunteerCreate = ({ id }) => post(`events/${id}/volunteers.json`)
  const volunteerGetWaitlist = ({ id }) =>
    get(`events/${id}/volunteers/waitlist`)
  const volunteerDelete = ({ eventId, volunteerId }) =>
    del(`events/${eventId}/volunteers/${volunteerId}.json`)

  const volunteerCheckIn = ({ eventId, volunteerId, checkInTime }) => {
    const body = { check_in_time: checkInTime }
    return put(`events/${eventId}/volunteers/${volunteerId}/check_in`, body)
  }

  const volunteerCheckOut = ({ checkOutTime, eventId, volunteerId }) => {
    const body = { check_out_time: checkOutTime }
    return put(`events/${eventId}/volunteers/${volunteerId}/check_out`, body)
  }

  const participantCheckIn = ({ checkInTime, eventId, participantId }) => {
    const body = { check_in_time: checkInTime }
    return put(`events/${eventId}/participants/${participantId}/check_in`, body)
  }

  const participantCheckOut = ({ checkOutTime, eventId, participantId }) => {
    const body = { check_out_time: checkOutTime }
    return put(
      `events/${eventId}/participants/${participantId}/check_out`,
      body,
    )
  }

  const sendFeedback = props => post('feedback.json', props)

  const getCivicParticipation = props => {
    if (!props.end_date) {
      const end = new Date()
      props.end_date = `${
        end.getMonth() + 1
      }-${end.getDate()}-${end.getFullYear()}`
    }
    if (!props.start_date) {
      const start = new Date()
      props.start_date = `${start.getMonth() + 1}-${start.getDate()}-${
        start.getFullYear() - 1
      }`
    }
    // was originally: return get(`users/${data.userId}/civic_participation.json`, data);
    return get(
      `users/${props.userId}/civic_participation.json?start_date=${props.start_date}&end_date=${props.end_date}`,
    )
  }

  const getShirtSizes = () => get('shirt_sizes.json')

  const requestAccountVerification = () =>
    put('email_verifications/request_code.json')

  const sendAccountVerificationCode = code =>
    put('email_verifications/verify.json', { code })

  const locationAutocomplete = useCallback(
    ({ input, latitude, longitude }) =>
      get(
        `locations/autocomplete.json?input=${input}&latlng=${latitude},${longitude}`,
      ),
    [get],
  )

  const locationPlaceDetails = useCallback(
    ({ placeId }) => get(`locations/place_details.json?place_id=${placeId}`),
    [get],
  )

  const charitiesSearch = useCallback(
    ({ query, page, perPage }) =>
      get(`charities/search.json?q=${query}&page=${page}&per_page=${perPage}`),
    [get],
  )

  const createCharityRegistration = useCallback(
    data => post('charity_registrations', data),
    [post],
  )

  const deleteCharityRegistration = useCallback(
    id => del(`charity_registrations/${id}.json`),
    [del],
  )

  const releaseCharityRegistrationEmail = useCallback(
    (id, data) =>
      post(
        `/charity_registrations/${id}/release_charity_registration_email.json`,
        data,
      ),
    [post],
  )

  const getUserCharityRegistrations = useCallback(
    () => get(`charity_registrations.json`),
    [get],
  )

  const getCharityTemplateEmail = useCallback(
    id => get(`/charities/${id}/get_custom_email_body.json`),
    [get],
  )

  const updateCharityTemplateEmail = useCallback(
    (id, data) => post(`/charities/${id}/save_custom_email_body.json`, data),
    [get],
  )

  const getBusiness = useCallback(id => get(`businesses/${id}.json`), [get])

  const updateBusiness = (id, formData) =>
    put(`businesses/${id}.json`, formData)

  const createBusiness = useCallback(
    data => post('businesses.json', data),
    [post],
  )

  const getBusinessCivPledges = useCallback(
    (id, params) =>
      get(
        `businesses/${id}/civ_pledges.json?${Object.keys(params)
          .map(key => key + '=' + params[key])
          .join('&')}`,
      ),
    [get],
  )

  const getIndustries = () => get('industries.json')

  const updateCivWallet = useCallback(
    (id, data) => post(`wallets/${id}/fund.json`, data),
    [post],
  )

  const getCivWallet = useCallback(id => get(`wallets/${id}.json`), [get])

  const getBankAccount = useCallback(
    id => get(`bank_accounts/${id}.json`),
    [get],
  )

  const createSponsorshipPledge = useCallback(
    data => post('civ_pledges.json', data),
    [post],
  )

  const createTmpSponsorshipPledge = useCallback(
    data => post('civ_pledges/create_tmp.json', data),
    [post],
  )

  const getAdminFee = useCallback(() => get('admin_fees.json'), [get])

  const getEventsWithPledges = useCallback(
    () => get('civ_pledges/events_with_pledges.json'),
    [get],
  )

  const civPledgeAction = useCallback(
    ({ action, pledgeId, eventId, message }) =>
      post(`/civ_pledges/${pledgeId}/${action}.json`, {
        event_id: eventId,
        message_from_event_owner: message,
      }),
    [post],
  )

  const civPledgeActionTmp = useCallback(
    ({ action, pledgeId, eventId, message }) =>
      post(`/civ_pledges/${pledgeId}/${action}_tmp.json`, {
        event_id: eventId,
        message_from_event_owner: message,
      }),
    [post],
  )

  const civPledgeSubmitPayment = useCallback(
    ({ pledgeId, amountPaid }) =>
      post(`/civ_pledges/${pledgeId}/submit_payment.json`, {
        amount_paid: amountPaid,
      }),
    [post],
  )

  const civPledgePay = useCallback(
    ({ pledgeId, amountPaid }) =>
      post(`/civ_pledges/${pledgeId}/pay.json`, {
        amount_paid: amountPaid,
      }),
    [post],
  )

  const civPledgeFinalize = useCallback(
    id => post(`/civ_pledges/${id}/finalize.json`, {}),
    [post],
  )

  const getPledge = id => get(`civ_pledges/${id}.json`)

  const getEventsPayForward = params =>
    get(
      `events/pay_forward_events.json?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const getEventAcceptedPledges = useCallback(
    id => get(`events/${id}/accepted_civ_pledges.json`),
    [get],
  )

  const updateEmailHeaders = email => {
    const newAuthHeaders = { ...authHeaders, uid: email }
    const newCurrentUser = {
      ...currentUser,
      email,
      uid: email,
    }
    localStorage.setItem('authHeaders', JSON.stringify(newAuthHeaders))
    localStorage.setItem('currentUser', JSON.stringify(newCurrentUser))
    window.location.reload()
  }

  const updateUserInformation = (userId, props) => {
    return api
      .put(`users/${userId}.json`, props)
      .catch(error => ({
        success: false,
        error,
        errors: error?.response?.data?.errors,
      }))
      .then(response => {
        // eslint-disable-next-line no-console
        return response
      })
  }

  const getCivPurchase = useCallback(
    id => get(`civ_purchases/${id}.json`),
    [get],
  )

  const getCivPurchases = params =>
    get(
      `civ_purchases.json?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const getCharityRegistrations = params =>
    get(
      `charity_registrations/accounting_index.json?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const getDisbursements = params =>
    get(
      `civ_disbursements/accounting_index.json?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const getCivillosWallet = (params, format = 'json') =>
    get(
      `civ_purchases/accounting_index.${format}?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const getMetaWallet = (params, format = 'json') =>
    get(
      `civ_pledges/accounting_index.${format}?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const getSponsorshipPayments = (params, format = 'json') =>
    get(
      `civ_pledges/sponsorship_payments.${format}?${Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')}`,
    )

  const uploadSponsorshipPayment = (id, data) =>
    post(`civ_pledges/${id}/upload_payment_confirmation.json`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  const updateCharityStatus = (id, data) =>
    patch(`charity_registrations/${id}/update_status.json`, data)

  const uploadReportDisbursement = data =>
    post('civ_disbursement_reports.json', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  const getCharities = useCallback(() => get('charities.json'), [get])

  const charitiesDonate = useCallback(
    (id, data) => post(`charities/${id}/donate.json`, data),
    [post],
  )

  const getCharityDonations = useCallback(
    id => get(`charities/${id}/donations.json`),
    [get],
  )

  const charitiesSendThankYouEmails = useCallback(
    (id, data) => post(`charities/${id}/send_thank_you_emails.json`, data),
    [post],
  )

  const civDonationResendEmail = useCallback(
    id => post(`civ_donations/${id}/resend_email.json`),
    [post],
  )

  const charitiesMarkAsDisbursed = useCallback(
    (id, data) => post(`civ_disbursements/${id}/mark_as_disbursed.json`, data),
    [post],
  )

  const getCharityDisbursements = useCallback(
    id => get(`charities/${id}/civ_disbursements.json`),
    [get],
  )

  const getDisbursementReport = useCallback(
    id => get(`civ_disbursements/${id}/accounting_show.csv`),
    [get],
  )

  const getSettingPledgeExpirationDays = () =>
    get(`settings/civ_pledge_expiration_days.json?${+new Date()}`)

  const updateEventVolunteers = (id, params) =>
    put(`events/${id}/volunteers/update_batch.json`, params)

  return {
    acceptUserFromGroup,
    addEventAdmin,
    addGroupAdmin,
    api,
    authHeaders,
    blockUserFromGroup,
    charitiesDonate,
    charitiesMarkAsDisbursed,
    charitiesSearch,
    charitiesSendThankYouEmails,
    civDonationResendEmail,
    civPledgeAction,
    civPledgeActionTmp,
    civPledgePay,
    civPledgeFinalize,
    civPledgeSubmitPayment,
    commentLike,
    commentUnlike,
    createAccount,
    createBusiness,
    createCharityRegistration,
    createEvent,
    createGroup,
    createMediaAttachment,
    createSponsorshipPledge,
    createTmpSponsorshipPledge,
    currentUser,
    declineUserFromGroup,
    del,
    deleteCharityRegistration,
    deleteComment,
    deleteEvent,
    deleteGroup,
    deletePhoto,
    editComment,
    emailAvailable,
    eventComment,
    eventLike,
    eventParticipate,
    eventSave,
    eventShare,
    eventSubscribeToCalendar,
    eventUnlike,
    eventUnparticipate,
    eventUnsave,
    eventUnsubscribeFromCalendar,
    forgotPassword,
    forgotPasswordCode,
    galleryLike,
    galleryUnlike,
    get,
    getAdminFee,
    getAssociations,
    getBankAccount,
    getBusiness,
    getBusinessCivPledges,
    getCharities,
    getCharityDisbursements,
    getCharityDonations,
    getCharityRegistrations,
    getCharityTemplateEmail,
    getCivicParticipation,
    getCivillosWallet,
    getCivPurchase,
    getCivPurchases,
    getCivWallet,
    getCurrentUser,
    getCurrentUserEvents,
    getCurrentUserFollowingEvents,
    getCurrentUserGroupEvents,
    getCurrentUserGroups,
    getDisbursementReport,
    getDisbursements,
    getEvent,
    getEventAcceptedPledges,
    getEventCategories,
    getEvents,
    getEventsPayForward,
    getEventsWithParams,
    getEventsWithPledges,
    getGroup,
    getGroupActivities,
    getGroupEngagementsPDFData,
    getGroupEvents,
    getGroupImpact,
    getGroupMembers,
    getHashtag,
    getIndustries,
    getMediaAttachmentUploadSignedUrl,
    getMetaWallet,
    getMonthlyEvents,
    getNotifications,
    getPledge,
    getPossibleAdmins,
    getPrivateAttachmentUploadSignedUrl,
    getSavedEvents,
    getSearchedAssociations,
    getSearchedEvents,
    getSettingPledgeExpirationDays,
    getShirtSizes,
    getSponsorshipOpportunities,
    getSponsorshipPayments,
    getTodaysEvents,
    getUnreadNotificationCount,
    getUser,
    getUserBlocked,
    getUserCharityRegistrations,
    getUserEngagementEvents,
    getUserEvents,
    getUserFollowers,
    getUserFollowing,
    getUserParticipatingEventsReadyToCheckin,
    getUserSuggestions,
    getUserVolunteeredEvents,
    getVersions,
    giveLikePlus,
    isSessionValid,
    joinAssociation,
    locationAutocomplete,
    locationPlaceDetails,
    participantCheckIn,
    participantCheckOut,
    participantEventRating,
    post,
    put,
    releaseCharityRegistrationEmail,
    removeEventAdmin,
    removeGroupAdmin,
    requestAccountVerification,
    requestEventAdminAccess,
    search,
    sendAccountVerificationCode,
    sendBlockedReason,
    sendFeedback,
    setCurrentUser,
    signInWithEmail,
    signInWithFacebookResponse,
    signOut,
    submitReport,
    takeEvent,
    unblockUserFromGroup,
    unjoinAssociation,
    updateAccount,
    updateBusiness,
    updateCharityStatus,
    updateCharityTemplateEmail,
    updateCivWallet,
    updateEvent,
    updateEventVolunteers,
    updateGroup,
    updatePassword,
    updateUser,
    updateUserEmail,
    updateUserQuote,
    updateEmailHeaders,
    updateUserInformation,
    uploadFileToS3,
    uploadReportDisbursement,
    uploadSponsorshipPayment,
    userBlock,
    userFollow,
    userUnblock,
    userUnfollow,
    volunteerCheckIn,
    volunteerCheckOut,
    volunteerCreate,
    volunteerDelete,
    volunteerEventRating,
    volunteerGet,
    volunteerGetWaitlist,
    volunteerUserRating,
  }
}
