import c from 'classnames'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import styles from './mentionList.module.scss'

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = index => {
    const item = props.items[index]

    if (item) {
      props.command({ id: item.nickname })
    }
  }

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length,
    )
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  if (!props.items.length) {
    return null
  }

  return (
    <div className={styles.dropdownMenu}>
      {!!props.items.length &&
        props.items.map((item, index) => (
          <div
            className={c(
              styles.dropdownItem,
              index === selectedIndex ? styles.isSelected : '',
            )}
            key={item.id}
            onClick={() => selectItem(index)}
          >
            <div>
              <div className={styles.name}>{item.name}</div>
              <div className={styles.nickName}>@{item.nickname}</div>
            </div>
          </div>
        ))}
    </div>
  )
})
