/* eslint-disable no-underscore-dangle */
import 'react-datepicker/dist/react-datepicker.css'
import {
  Accordion,
  ProgressBar,
  Alert,
  Modal,
  FormCheck,
} from 'react-bootstrap'
import { Buffer } from 'buffer'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { formatLocation } from '../../lib/address'
import { isVideo } from '../../lib/files'
import { restoreState, storeState } from '../../lib/localstorage'
import {
  sortCategoriesAlphabetically,
  sortObjectsAlphabetically,
} from '../../lib/sort'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import useApi from '../../hooks/useApi'
import AdminPicker from '../../components/Event/adminPicker'
import TaskAndShiftForm from '../../components/Event/taskAndShift'
import VolunteerPill from '../../components/Event/volunteerPill'
import ModalDates from '../../components/Event/modalDates'
import ModalDelete from '../../components/Event/modalDelete'
import ModalDraft from '../../components/Event/modalDraft'
import ModalEnroll from '../../components/Event/modalEnroll'
import ModalPrivate from '../../components/Event/modalPrivate'
import ModalVolunteerCount from '../../components/Event/modalVolunteerCount'
import Badge from '../../components/badge'
import Button from '../../components/button'
import Icon from '../../components/icon'
import Loading from '../../components/loading'
import LocationSearch from '../../components/location-search'
import Title from '../../components/title'
import upload from '../../constants/upload'
import Uploader from '../../components/uploader/uploader'
import UserPill from '../../components/user-pill'
import TextareaEditor from '../../components/TextareaEditor'

registerLocale('es', es)
setDefaultLocale('en')

export default function EventForm(props) {
  const { eventId = null, isNew = false } = props
  const navigate = useNavigate()

  const { t } = useTranslation()
  const {
    addEventAdmin,
    createEvent,
    createMediaAttachment,
    deleteEvent,
    getCurrentUserGroups,
    getUserSuggestions,
    getEvent,
    getEventCategories,
    getMediaAttachmentUploadSignedUrl,
    removeEventAdmin,
    updateEvent,
    uploadFileToS3,
    getPossibleAdmins,
  } = useApi()
  const { currentUser, locale, hasBetaFeature } = useCurrentUser()

  const [accordionKey, setAccordionKey] = useState('0')
  const [adminPickerOpen, setAdminPickerOpen] = useState(false)
  const [adminsInitial, setAdminsInitial] = useState([])
  const [categories, setCategories] = useState(() =>
    restoreState('categories', []),
  )
  const [event, setEvent] = useState({})
  const [eventType, setEventType] = useState()
  const [groups, setGroups] = useState(() => restoreState('groups', []))
  const [isDeleting, setIsDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isOwner, setIsOwner] = useState(false)
  const [location, setLocation] = useState()
  const [originalEvent, setOriginalEvent] = useState({})
  const [showDraftInfo, setShowDraftInfo] = useState(false)
  const [showInvalidVolunteerCountModal, setShowInvalidVolunteerCountModal] =
    useState(false)
  const [showTaskShiftForm, setShowTaskShiftForm] = useState(false)
  const [validationError, setValidationError] = useState('')
  const [validatingVolunteers, setValidatingVolunteers] = useState(false)
  const [showEnrollInfo, setShowEnrollInfo] = useState(false)
  const [showInvalidPrivateModal, setShowInvalidPrivateModal] = useState(false)
  const [showInvalidDates, setShowInvalidDates] = useState(false)

  const isEvent = useMemo(() => eventType === 'event', [eventType])
  const isNeed = useMemo(() => eventType === 'need', [eventType])
  const isVolunteerOpportunity = useMemo(
    () => eventType === 'volunteer_opportunity',
    [eventType],
  )
  const [invalidTaskLeaders, setInvalidTaskLeaders] = useState([])
  const [invalidAdmins, setInvalidAdmins] = useState([])

  const attachmentReducer = (state, action) => {
    const newState = [...state]

    if (Array.isArray(action)) {
      action.forEach(a => {
        const attachmentIdx = state.findIndex(
          attachment =>
            attachment.uploadId === a.uploadId || attachment.id === a.id,
        )

        if (attachmentIdx >= 0) {
          if (a.delete) {
            newState.splice(attachmentIdx, 1)
          } else {
            newState[attachmentIdx] = a
          }
        } else {
          newState.push(a)
        }
      })
    } else {
      const attachmentIdx = state.findIndex(
        attachment =>
          attachment?.uploadId === action?.uploadId ||
          attachment?.id === action?.id,
      )

      if (attachmentIdx >= 0) {
        if (action.delete) {
          newState.splice(attachmentIdx, 1)
        } else {
          newState[attachmentIdx] = action
        }
      }
    }
    return newState
  }

  const [mediaAttachments, setMediaAttachments] = React.useReducer(
    attachmentReducer,
    event.media_attachments || [],
  )

  const onLocationChange = useCallback(
    _location => {
      if (!_location) {
        setLocation(null)
        return
      }
      const defaultAddress = _location.description
      const addressLine1 = _location.adr_address.match(
        '<span className="street-address">(.*?)</span>',
      )
      const city = _location.adr_address.match(
        '<span className="locality">(.*?)</span>',
      )
      const state = _location.adr_address.match(
        '<span className="region">(.*?)</span>',
      )
      const zip = _location.adr_address.match(
        '<span className="postal-code">(.*?)</span>',
      )

      setLocation({
        formatted_address: defaultAddress,
        name: defaultAddress,
        address_line_1: addressLine1 ? addressLine1[1] : defaultAddress,
        address_line_2: '',
        city: city ? city[1] : '',
        state: state ? state[1] : '',
        zip: zip ? zip[1] : '',
        latitude: Number.parseFloat(
          _location.geometry.location.lat,
        ).toPrecision(7),
        longitude: Number.parseFloat(
          _location.geometry.location.lng,
        ).toPrecision(7),
        google_location_id: _location.place_id,
        google_description: defaultAddress,
      })
    },
    [event, location, setLocation],
  )

  const fetchData = async () => {
    if (groups?.length || categories?.length) {
      setIsLoading(false)
    }
    const { data: _groups } = await getCurrentUserGroups()
    if (_groups) {
      setGroups(sortObjectsAlphabetically(_groups, 'name'))
    }
    const { data: _categories } = await getEventCategories()
    if (_categories) {
      setCategories(sortCategoriesAlphabetically(_categories, locale))
    }
    setIsLoading(false)
  }

  const fetchEvent = async () => {
    const { data } = await getEvent({ id: eventId })
    if (data) {
      let type = data.event_type
      if (type === 'event') {
        if (data.volunteers_needed > 0) {
          type = 'volunteer_opportunity'
        }
      }
      setAdminsInitial(data.admins)
      setOriginalEvent(data)
      setEvent(data)
      setEventType(type)
      setLocation(data.location)
      data.media_attachments.forEach(media_attachment => {
        media_attachment.uploadId =
          performance.now().toString(36) + Math.random().toString(36).substr(2)
      })
      setMediaAttachments(data.media_attachments)
    }
    fetchData()
  }

  const setEventProp = useCallback(
    (prop, value) => {
      const newEvent = { ...event, [prop]: value }
      setEvent(newEvent)
      return newEvent
    },
    [event],
  )

  const handleUploadComplete = useCallback(
    response => {
      setMediaAttachments([...mediaAttachments, response])
    },
    [mediaAttachments],
  )

  const handleRemoveFile = useCallback(
    id => {
      setMediaAttachments({ id, delete: true })
    },
    [event, mediaAttachments],
  )

  const onToggleCategory = useCallback(
    category => {
      const hasCategory = event.categories?.find(c => c.id === category.id)
      const newCategories = hasCategory
        ? event.categories?.filter(c => c.id !== category.id)
        : [...(event.categories ?? []), category]
      setEventProp('categories', newCategories)
    },
    [event],
  )

  const onToggleGroup = useCallback(
    group => {
      const hasGroup = event.groups?.find(c => c.id === group.id)
      const newGroups = hasGroup
        ? event.groups?.filter(c => c.id !== group.id)
        : [...(event.groups ?? []), group]
      setEventProp('groups', newGroups)
    },
    [event],
  )

  const organizeHashtags = tags => {
    if (!tags) return ''
    const cleanHashtags = tags
      .replace(/[#, ]+/g, ' ')
      .trim()
      .split(' ')
    const reformattedHashtags = cleanHashtags.map(tag => `#${tag}`)

    return reformattedHashtags.join(' ')
  }

  const shiftVolunteersNeeded = useMemo(() => {
    if (event.tasks?.length === 0) {
      return 0
    }
    const volunteersNeeded = event.tasks
      ?.filter(task => !task._destroy)
      .reduce(
        (acc, task) =>
          acc +
          task.shifts.reduce(
            (a, shift) =>
              a + (shift._destroy ? 0 : parseInt(shift.volunteers_needed, 10)),
            0,
          ),
        0,
      )
    return volunteersNeeded ?? event.volunteers_needed
  }, [event, event.volunteers_needed])

  // Search a user within an event groups
  const isValidPrivateAdmin = async userSearch => {
    const { data } = await getPossibleAdmins({
      groups: event.groups,
      query: userSearch.name,
      page: 1,
    })
    // verify if results are return and the ID Match
    return data.length > 0 && data.findIndex(d => d.id === userSearch.id) !== -1
  }
  // Iterate all possible users
  const validateAllPrivateUsers = () => {
    // Admins
    if (event.admins?.length) {
      Promise.all(
        event.admins?.map(async a => ({
          id: a.id,
          valid: await isValidPrivateAdmin(a),
        })),
      ).then(admins => {
        setInvalidAdmins(admins.filter(r => !r.valid).map(i => i.id))
      })
    } else {
      // No Admin selected
      setInvalidAdmins([])
    }
    // Tasks Leaders
    const tasksLeaders = []
    event.tasks?.forEach(t => {
      t.task_leader_users.forEach(l =>
        tasksLeaders.push({ task: t, leader: l }),
      )
    })
    if (tasksLeaders.length) {
      Promise.all(
        tasksLeaders.map(async l => ({
          id: l.leader.id,
          valid: await isValidPrivateAdmin(l.leader),
        })),
      ).then(leaders => {
        setInvalidTaskLeaders(leaders.filter(r => !r.valid).map(i => i.id))
      })
    } else {
      // No task leader selected
      setInvalidTaskLeaders([])
    }
  }

  const onSave = useCallback(
    async ({ isDraft, updatedEvent }) => {
      const _event = { ...event, ...updatedEvent }
      setValidatingVolunteers(true)
      // Validation
      if (!_event.title) {
        setValidationError(t('event.title_required'))
        return
      }
      if (!_event.details && !isDraft) {
        setValidationError(t('event.details_required'))
        return
      }
      if (!location && !_event.remote && !isDraft) {
        setValidationError(t('event.location_required'))
        return
      }
      if (!_event.categories?.length && !isDraft) {
        setValidationError(t('event.categories_required'))
        return
      }
      if (
        (!mediaAttachments.length || mediaAttachments.find(a => a.uploading)) &&
        !isDraft
      ) {
        setValidationError(t('event.media_required'))
        return
      }
      if (_event.is_private && !_event.groups && !isDraft) {
        setValidationError(t('event.groups_required'))
        return
      }
      if (
        _event.volunteers_needed !== shiftVolunteersNeeded &&
        _event.tasks?.length > 0 &&
        !isDraft
      ) {
        setShowInvalidVolunteerCountModal(true)
        return
      }
      let startShiftError = false
      let endShiftError = false
      if (_event.tasks?.length) {
        const tasks = _event.tasks?.filter(t => !t._destroy)
        for (let tIdx = 0; tIdx < tasks?.length; tIdx += 1) {
          for (let sIdx = 0; sIdx < tasks[tIdx].shifts.length; sIdx += 1) {
            const shift = tasks[tIdx].shifts[sIdx]
            if (
              moment(shift.start_time).isBefore(moment(_event.event_start_date))
            ) {
              startShiftError = true
            }
            if (moment(shift.end_time).isAfter(moment(_event.event_end_date))) {
              endShiftError = true
            }
          }
        }
      }
      if (
        !!_event.volunteers_needed &&
        _event.tasks?.length > 0 &&
        (startShiftError || endShiftError) &&
        !isDraft
      ) {
        setShowInvalidDates(true)
        return
      }
      if (invalidAdmins.length || invalidTaskLeaders.length) {
        setShowInvalidPrivateModal(true)
        return
      }

      const defaultCategory = categories.find(
        c => c.en_name === 'Community Improvement',
      )

      const defaultLocation = {
        name: 'United States',
        address_line_1: 'United States',
        address_line_2: '',
        city: '',
        state: '',
        zip: '',
        latitude: '37.09024',
        longitude: '-95.71289',
        google_location_id: 'ChIJCzYy5IS16lQRQrfeQ5K5Oxw',
        google_description: 'United States',
      }

      setIsLoading(true)
      const formData = {
        draft: isDraft,
        details: _event.details,
        hashtags: organizeHashtags(_event.hashtags),
        event_type: _event.event_type,
        eventId: _event.id,
        is_private: _event.is_private,
        remote: _event.remote,
        event_start_date: _event.event_start_date
          ? moment(_event.event_start_date).toISOString()
          : undefined,
        event_end_date: _event.event_end_date
          ? moment(_event.event_end_date).toISOString()
          : undefined,
        volunteers_start_date: _event.event_start_date
          ? moment(_event.event_start_date).toISOString()
          : undefined,
        volunteers_end_date: _event.event_end_date
          ? moment(_event.event_end_date).toISOString()
          : undefined,
        service_hours: _event.service_hours,
        volunteer_pay_forward: _event.volunteer_pay_forward,
        title: _event.title,
        volunteers_details: _event.volunteers_details,
        volunteers_needed: _event.volunteers_needed,
        group_ids: _event.groups?.map(g => g.id),
        category_ids:
          _event.categories && _event.categories.length > 0
            ? _event.categories.map(c => c.id)
            : [defaultCategory.id],
        media_attachment_ids: mediaAttachments.map(a => a.id),
        participant_signup: isEvent,
        ...(_event.remote ? {} : { location: location || defaultLocation }),
        ...(_event.tasks
          ? {
              tasks_attributes:
                _event.tasks.map(task => ({
                  id: task.id,
                  ...(task._destroy
                    ? { _destroy: true }
                    : {
                        title: task.title,
                        position: task.position,
                        task_leader_user_ids: task.task_leader_users.map(
                          u => u.id,
                        ),
                        shifts_attributes: task.shifts.map(shift => ({
                          ...(shift.id ? { id: shift.id } : {}),
                          ...(shift._destroy
                            ? { _destroy: true }
                            : {
                                start_time: moment(shift.start_time).toDate(),
                                end_time: moment(shift.end_time).toDate(),
                                volunteers_needed: shift.volunteers_needed,
                              }),
                        })),
                      }),
                })) || [],
            }
          : {}),
      }

      if (isNew) {
        const { data, errors } = await createEvent(formData)
        if (!errors) {
          const url = `/events/${data.slug ?? data.id}`
          navigate(url, { replace: true })
        }
      } else {
        // check each key in formData and remove any keys that did not change
        const filteredFormData = Object.keys(formData).reduce((acc, key) => {
          if (formData[key] !== originalEvent[key]) {
            acc[key] = formData[key]
          }
          return acc
        }, {})

        filteredFormData.id = _event.id
        const { data } = await updateEvent(_event.id, filteredFormData)

        // Add/remove admins
        const adminsToAdd = _event.admins?.filter(
          a => !adminsInitial.includes(a),
        )
        if (adminsToAdd.length) {
          await Promise.all(
            adminsToAdd.map(a =>
              addEventAdmin({ eventId: _event.id, userId: a.id }),
            ),
          )
        }
        const adminsToRemove = adminsInitial.filter(
          a => !_event.admins?.includes(a),
        )
        if (adminsToRemove.length) {
          await Promise.all(
            adminsToRemove.map(a =>
              removeEventAdmin({ eventId: _event.id, userId: a.id }),
            ),
          )
        }

        if (data) {
          navigate(`/events/${data.slug ?? data.id}`)
        }
      }
      setIsLoading(false)
    },
    [
      addEventAdmin,
      adminsInitial,
      event,
      eventId,
      isEvent,
      location,
      mediaAttachments,
      removeEventAdmin,
      shiftVolunteersNeeded,
    ],
  )

  const onSetupEvent = useCallback(() => {
    setEventType('event')
    setEvent({
      address: '',
      categories: [],
      details: '',
      event_end_date: moment().startOf('hour').add(1, 'hours').toISOString(),
      event_start_date: moment().startOf('hour').toISOString(),
      event_type: 'event',
      media_attachments: [],
      groups: [],
      hashtags: '',
      id: undefined,
      is_private: false,
      remote: false,
      title: '',
      volunteers_details: undefined,
      volunteers_needed: 0,
    })
  }, [setEventProp])

  const onSetupNeed = useCallback(() => {
    setEventType('need')
    setEvent({
      address: '',
      categories: [],
      details: '',
      event_end_date: undefined,
      event_start_date: undefined,
      event_type: 'need',
      media_attachments: [],
      groups: [],
      hashtags: '',
      id: undefined,
      is_private: false,
      remote: false,
      title: '',
      volunteers_details: undefined,
      volunteers_needed: 0,
    })
  }, [setEventProp])

  const onSetupVolunteerOpportunity = useCallback(() => {
    setEventType('volunteer_opportunity')
    setEvent({
      address: '',
      categories: [],
      details: '',
      event_end_date: moment().startOf('hour').add(1, 'hours').toISOString(),
      event_start_date: moment().startOf('hour').toISOString(),
      event_type: 'event',
      media_attachments: [],
      groups: [],
      tasks: [],
      hashtags: '',
      id: undefined,
      is_private: false,
      remote: false,
      title: '',
      volunteers_details: '',
      volunteers_needed: 1,
    })
  }, [setEventProp])

  const onClickSetPrivate = useCallback(
    status => {
      setEventProp('is_private', status)
      setAccordionKey('groups')
    },
    [setEventProp, setAccordionKey],
  )

  const onAddAdmin = useCallback(
    admin => {
      if (event.admins?.length < 5) {
        if (!event.admins.includes(admin)) {
          setEventProp('admins', [...(event.admins ?? []), admin])
        }
      }
    },
    [event],
  )

  const onRemoveAdmin = useCallback(
    admin => {
      setEventProp(
        'admins',
        event.admins?.filter(a => a.id !== admin.id),
      )
    },
    [event],
  )

  const onDeleteRequest = useCallback(() => {
    if (event.id && event.drafted_at) {
      setIsDeleting(true)
    }
  })

  const onDelete = useCallback(() => {
    deleteEvent(event.id).finally(() => {
      navigate('/')
    })
  })

  const onCloseAdminPicker = useCallback(() => {
    setAdminPickerOpen(false)
  }, [])

  const onOpenAdminPicker = useCallback(() => {
    setAdminPickerOpen(true)
  }, [])

  const toggleAccordionKey = useCallback(
    key => {
      if (accordionKey === key) {
        setAccordionKey(null)
      } else {
        setAccordionKey(key)
      }
    },
    [accordionKey],
  )

  useEffect(() => {
    if (!isNew && eventId) {
      fetchEvent()
    } else {
      fetchData()
    }
  }, [eventId])

  useEffect(() => {
    setIsOwner(!event?.id || event?.user?.id === currentUser?.id)
  }, [event])

  useEffect(() => {
    storeState('groups', groups)
    storeState('categories', categories)
  }, [categories, groups])

  useEffect(() => {
    // Validate Admins and Tasks Leaders if change to private
    if (event?.is_private) {
      validateAllPrivateUsers()
    } else {
      // Clear previous values
      setInvalidAdmins([])
      setInvalidTaskLeaders([])
    }
  }, [event.groups, event.is_private, event.tasks, event.admins])

  const isNotValid = useMemo(() => {
    const notValid =
      !event.categories?.length ||
      !event.details ||
      !event.title ||
      !mediaAttachments.length ||
      (!isNeed && (!event.event_start_date || !event.event_end_date)) ||
      (!location && !event.remote) ||
      (event.is_private && !event.groups?.length) ||
      (isVolunteerOpportunity && event.volunteers_needed === 0) ||
      mediaAttachments.some(a => !!a.uploading) ||
      isLoading
    // console.log("Valid: ", !notValid);
    return notValid
  }, [
    event,
    isLoading,
    location,
    mediaAttachments,
    isNeed,
    isVolunteerOpportunity,
  ])

  const sortedAdmins = useMemo(() => {
    if (event.admins) {
      return event.admins.sort((a, b) => {
        if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
          return -1
        }
        if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
    return []
  }, [event.admins])

  const renderUploaderButton = useCallback(() => {
    return (
      <div id="drop-area">
        <p>{t('event.media_drop')}</p>
        <p>* {t('event.media_supported')}</p>
        <label className="btn btn-primary" htmlFor="images">
          {t('event.media_upload_button')}
        </label>
      </div>
    )
  }, [])

  if (!isNew && isLoading) {
    return <Loading show={isLoading} />
  }

  if (showTaskShiftForm) {
    return (
      <TaskAndShiftForm
        event={event}
        setEventProp={setEventProp}
        open={setShowTaskShiftForm}
        invalidTaskLeaders={invalidTaskLeaders}
      />
    )
  }

  return (
    <div className="container">
      <div className="row">
        <Title>{isNew ? t('event.create_post') : t('event.edit_post')}</Title>
      </div>
      {isNew && (
        <div className="row">
          <Loading show={isLoading} />
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body eventTypes">
                <div
                  id="needTypeIndicator"
                  className="typeIndicator btn-outline-danger"
                >
                  <Icon name="bullhorn" />
                </div>
                <h5 className="card-title">{t('event.needs_announce')}</h5>
                <p className="card-text">{t('event.report_need')}</p>
                <Button
                  id="needTypeButton"
                  type="button"
                  variant="checkbox"
                  className={isNeed ? 'active' : ''}
                  onClick={onSetupNeed}
                  disabled={!isOwner}
                >
                  {t('event.create_post')}
                </Button>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body eventTypes">
                <div
                  id="volTypeIndicator"
                  className="typeIndicator btn-outline-danger"
                >
                  <Icon name="hand-paper" />
                </div>
                <h5 className="card-title">
                  {t('event.volunteer_opportunity')}
                </h5>
                <p className="card-text">{t('event.call_to_action')}</p>
                <Button
                  id="volTypeButton"
                  type="button"
                  variant="checkbox"
                  disabled={!isOwner}
                  className={isVolunteerOpportunity ? 'active' : ''}
                  onClick={onSetupVolunteerOpportunity}
                >
                  {t('event.create_post')}
                </Button>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card">
              <div className="card-body eventTypes">
                <div
                  id="eventTypeIndicator"
                  className="typeIndicator btn-outline-danger"
                >
                  <Icon name="users" />
                </div>
                <h5 className="card-title">{t('event.events')}</h5>
                <p className="card-text">{t('event.rally')}</p>
                <Button
                  id="eventTypeButton"
                  type="button"
                  variant="checkbox"
                  disabled={!isOwner}
                  className={isEvent ? 'active' : ''}
                  onClick={onSetupEvent}
                >
                  {t('event.create_post')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {!!event.event_type && (
        <div
          className={`eventFormContent postForm ${
            !!event.id && 'postForm__edit'
          }`}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                * <label htmlFor="title">{t('event.title')}</label>
                <input
                  className="form-control"
                  id="title"
                  maxLength="84"
                  title="title"
                  type="text"
                  onChange={e => setEventProp('title', e.target.value)}
                  value={event.title}
                />
              </div>
              <div className="form-group">
                * <label htmlFor="details">{t('event.details')}</label>
                <TextareaEditor
                  getOptions={getUserSuggestions}
                  text={event.details}
                  onChangeText={text => setEventProp('details', text)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="hashtags">{t('event.add_hashtags')}</label>

                <input
                  className="form-control"
                  id="hashtags"
                  name="hashtags"
                  type="text"
                  onChange={e => setEventProp('hashtags', e.target.value)}
                  value={event.hashtags}
                />
              </div>

              {!event.remote && (
                <div id="locationForm" className="form-group">
                  <label htmlFor="location">
                    {t('users.edit.location')}
                    &nbsp;
                    <small>{t('event.location_hint')}</small>
                  </label>
                  <LocationSearch
                    onChange={onLocationChange}
                    placeholder={t('users.edit.search')}
                    value={formatLocation(event?.location || location)}
                  />
                </div>
              )}
              <div className="form-check">
                <input
                  type="checkbox"
                  checked={event.remote}
                  className="form-check-input chk-checkbox"
                  id="remote"
                  name="remote"
                  onChange={e => setEventProp('remote', e.target.checked)}
                />
                <label htmlFor="remote" className="form-check-label">
                  {t('event.remote')}{' '}
                </label>
              </div>
              <br />

              {event.event_type !== 'need' && (
                <div className="row">
                  <div className="col">
                    <div className="eventExtra form-group">
                      <label htmlFor="selectStartDate">
                        * {t('event.start_date')}
                      </label>
                      <DatePicker
                        className="form-control timestring_form_start"
                        dateFormat={t('config.datetime_picker')}
                        id="startDate"
                        locale={locale}
                        minDate={new Date()}
                        selected={moment(
                          event.event_start_date ?? new Date(),
                        ).toDate()}
                        shouldCloseOnSelect
                        showTimeSelect
                        timeCaption={t('event.time')}
                        timeFormat="h:mm aa"
                        onChange={date => {
                          setEventProp('event_start_date', date)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="eventExtra form-group">
                      <label htmlFor="selectEndDate">
                        * {t('event.end_date')}
                      </label>
                      <DatePicker
                        className="form-control timestring_form_end"
                        dateFormat={t('config.datetime_picker')}
                        id="endDate"
                        locale={locale}
                        minDate={new Date(event.event_start_date)}
                        selected={
                          +new Date(event.event_start_date) >=
                          +new Date(event.event_end_date)
                            ? moment(event.event_start_date ?? new Date())
                                .add(1, 'hours')
                                .toDate()
                            : moment(
                                event.event_end_date ?? new Date(),
                              ).toDate()
                        }
                        shouldCloseOnSelect
                        showTimeSelect
                        timeCaption={t('event.time')}
                        timeFormat="h:mm aa"
                        onChange={date => {
                          setEventProp('event_end_date', date)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {isVolunteerOpportunity && (
                <>
                  <div className="form-group volunteerExtra">
                    <br />
                    <div className="title">
                      {t('event.volunteers_form_section_title')}
                    </div>
                    <label htmlFor="inputLocation">
                      * {t('event.volunteers_needed')}:{' '}
                      <small>
                        {t('event.volunteers_needed_requirement_info')}
                      </small>
                    </label>
                    <div className="flex-row">
                      <input
                        className={`form-control eventVolunteersNeeded ${
                          validatingVolunteers &&
                          event.tasks?.length > 0 &&
                          event.volunteers_needed !== shiftVolunteersNeeded
                            ? 'invalid'
                            : ''
                        }`}
                        id="volunteersNeeded"
                        min={0}
                        name="volunteers_needed"
                        placeholder={0}
                        type="number"
                        onChange={e =>
                          setEventProp(
                            'volunteers_needed',
                            Math.abs(parseInt(e.target.value, 10)),
                          )
                        }
                        value={parseInt(event.volunteers_needed, 10)}
                      />
                      <div className="volunteer-shifts-title ">
                        <span>{t('event.tasks_and_shifts')}</span>
                      </div>
                      <div className="volunteer-pill-container">
                        <div
                          className={`task-button ${
                            invalidTaskLeaders.length ? 'invalid' : ''
                          }`}
                          onClick={() => setShowTaskShiftForm(true)}
                          onKeyDown={() => setShowTaskShiftForm(true)}
                          role="button"
                          tabIndex={0}
                        >
                          {t('event.add_task_shift')}
                        </div>
                        <VolunteerPill
                          count={
                            event.tasks?.filter(task => !task._destroy).length
                          }
                          label={t('event.volunteers_needed_tasks_count')}
                        />
                        <VolunteerPill
                          count={event.tasks
                            ?.filter(task => !task._destroy)
                            .reduce(
                              (acc, task) =>
                                acc +
                                (task.shifts?.filter(s => !s._destroy).length ??
                                  0),
                              0,
                            )}
                          label={t('event.volunteers_needed_shifts_count')}
                        />
                        <VolunteerPill
                          invalid={
                            validatingVolunteers &&
                            event.tasks?.length > 0 &&
                            event.volunteers_needed !== shiftVolunteersNeeded
                          }
                          count={shiftVolunteersNeeded}
                          label={t('event.volunteers_needed_volunteers_count')}
                        />
                      </div>
                    </div>

                    <label htmlFor="inputVolInfo">
                      {t('event.volunteer_info')}
                    </label>
                    <TextareaEditor
                      getOptions={getUserSuggestions}
                      text={event.volunteers_details}
                      onChangeText={text =>
                        setEventProp('volunteers_details', text)
                      }
                    />
                  </div>
                  <div className="col">
                    <div className="form-group serviceHourExtra">
                      <br />
                      <input
                        type="checkbox"
                        checked={event.service_hours}
                        className="form-check-input chk-checkbox"
                        id="service_hours"
                        onChange={e =>
                          setEventProp('service_hours', e.target.checked)
                        }
                      />{' '}
                      <label
                        htmlFor="service_hours"
                        className="form-check-label"
                      >
                        {t('event.can_earn_service_hours')}
                      </label>
                    </div>
                  </div>
                  {hasBetaFeature('pf_organizer') && (
                    <FormCheck>
                      <FormCheck.Input
                        type="checkbox"
                        checked={event?.volunteer_pay_forward}
                        className={'chk-checkbox'}
                        onChange={e =>
                          setEventProp(
                            'volunteer_pay_forward',
                            e.target.checked,
                          )
                        }
                      />
                      <FormCheck.Label className="badge-container">
                        {t('event.enroll.text')}
                        <a
                          className="info-circle ms-1"
                          onClick={() => setShowEnrollInfo(true)}
                        >
                          {t('event.enroll.link')}
                          <Icon
                            name="info-circle"
                            className={'info-circle-icon'}
                          />
                        </a>
                        <Badge />
                      </FormCheck.Label>
                    </FormCheck>
                  )}
                </>
              )}
            </div>

            <div className="col-lg-6">
              <Accordion defaultActiveKey="0" activeKey={accordionKey}>
                {!!groups?.length && isOwner && (
                  <>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header onClick={() => toggleAccordionKey('0')}>
                        {t('event.privacy_settings')}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Button
                          type="button"
                          variant="checkbox"
                          className={!event.is_private ? 'active' : ''}
                          onClick={() => onClickSetPrivate(false)}
                        >
                          <Icon name="globe-americas" /> {t('event.public')}
                        </Button>
                        <Button
                          type="button"
                          variant="checkbox"
                          className={event.is_private ? 'active' : ''}
                          onClick={() => onClickSetPrivate(true)}
                        >
                          <Icon name="lock" /> {t('event.private')}
                        </Button>
                      </Accordion.Body>
                    </Accordion.Item>
                    {(isOwner || !event.is_private) && (
                      <Accordion.Item eventKey="groups">
                        <Accordion.Header
                          onClick={() => toggleAccordionKey('groups')}
                        >
                          {event.is_private
                            ? t('event.private_group_settings')
                            : t('event.public_group_settings')}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="mb-2">
                            {event.is_private
                              ? t('event.private_group_instructions')
                              : t('event.public_group_instructions')}
                          </div>
                          {groups.map(group => (
                            <Button
                              key={group.id}
                              type="button"
                              variant="checkbox"
                              className={
                                event?.groups?.some(g => g.id === group.id)
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => onToggleGroup(group)}
                            >
                              {group.name}
                            </Button>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </>
                )}

                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={() => toggleAccordionKey('2')}>
                    {t('event.categories_add')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="mb-2">{t('event.categories_select')}</div>
                    {categories.map(category => (
                      <Button
                        key={category.id}
                        type="button"
                        variant="checkbox"
                        className={
                          event?.categories?.some(c => c.id === category.id)
                            ? 'active'
                            : ''
                        }
                        onClick={() => onToggleCategory(category)}
                      >
                        <Icon name={category.icon} />{' '}
                        {currentUser?.language === 'es'
                          ? category.es_name
                          : category.en_name}
                      </Button>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>

                {!!event.id && isOwner && (
                  <Accordion.Item eventKey="admins">
                    <Accordion.Header
                      onClick={() => toggleAccordionKey('admins')}
                      className={
                        invalidAdmins.length ? 'invalid-accordion-header' : ''
                      }
                    >
                      {t('event.add_admins_admin')} (
                      {t('event.add_admins_limit', { seats: 5 })})
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-2">
                        <Button type="button" onClick={onOpenAdminPicker}>
                          <Icon name="plus" /> {t('event.add_admins')}
                        </Button>
                      </div>
                      {sortedAdmins.map(admin => (
                        <UserPill
                          key={admin.id.toString()}
                          user={admin}
                          onRemove={() => onRemoveAdmin(admin)}
                        />
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={() => toggleAccordionKey('3')}>
                    {t('event.media_upload')}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Uploader
                      accept="image/*,video/*"
                      mediaAttachments={mediaAttachments}
                      multiple
                      onRemove={handleRemoveFile}
                      onUploadComplete={handleUploadComplete}
                      renderButton={renderUploaderButton()}
                      type="media"
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="row marginBtns">
            <div className="col-lg-8">
              {(!event.id || event.drafted_at) && (
                <>
                  <div
                    className="btn btn-lg btn-link"
                    onClick={() => setShowDraftInfo(true)}
                    role="presentation"
                  >
                    <Icon name={'info-circle'} />
                  </div>
                  <input
                    className="btn btn-lg btn-link"
                    disabled={event.title === '' && isLoading}
                    id="event-form-draft"
                    onClick={() => onSave({ isDraft: true })}
                    type="submit"
                    value={t('users.edit.saveDraft')}
                  />
                </>
              )}
              {event.id && event.drafted_at && (
                <div
                  className="btn btn-lg btn-link"
                  onClick={onDeleteRequest}
                  role="presentation"
                >
                  {t('users.edit.delete')}
                </div>
              )}
            </div>
            <div className="col-lg-4 create-btn">
              {!!validationError?.length && (
                <Alert
                  dismissible
                  onClose={() => setValidationError('')}
                  show
                  variant="danger"
                >
                  {validationError}
                </Alert>
              )}
              <Link to="/">
                <Button variant="secondary" className="btn-lg m-2">
                  {t('users.edit.cancel')}
                </Button>
              </Link>
              <input
                className="btn btn-lg btn-primary m-2"
                disabled={isNotValid}
                id="event-form-submit"
                onClick={() => onSave({ isDraft: false })}
                type="submit"
                value={t('users.edit.publish')}
              />
            </div>
          </div>
        </div>
      )}
      <AdminPicker
        admins={event.admins}
        eventId={event.id}
        groups={event.is_private ? event.groups : undefined}
        isOpen={adminPickerOpen}
        onAddAdmin={onAddAdmin}
        onClose={onCloseAdminPicker}
        onRemoveAdmin={onRemoveAdmin}
        searchTitle={t('event.add_admins_limit', { seats: 5 })}
      />
      <ModalDelete
        show={isDeleting}
        onHide={() => setIsDeleting(false)}
        onClick={onDelete}
      />
      <ModalDates
        show={showInvalidDates}
        onHide={() => setShowInvalidDates(false)}
        onClick={() => {
          setShowInvalidDates(false)
          setShowTaskShiftForm(true)
        }}
      />
      <ModalDraft
        show={showDraftInfo}
        onHide={() => setShowDraftInfo(false)}
        onClick={() => setShowDraftInfo(false)}
      />
      <ModalVolunteerCount
        show={showInvalidVolunteerCountModal}
        onHide={() => setShowInvalidVolunteerCountModal(false)}
        onClick={() => {
          const newEvent = setEventProp(
            'volunteers_needed',
            shiftVolunteersNeeded,
          )
          onSave({ isDraft: false, updatedEvent: newEvent })
          setShowInvalidVolunteerCountModal(false)
        }}
        shiftVolunteersNeeded={shiftVolunteersNeeded}
        event={event}
      />
      <ModalEnroll
        show={showEnrollInfo}
        onHide={() => setShowEnrollInfo(false)}
        onClick={() => setShowEnrollInfo(false)}
      />
      <ModalPrivate
        show={showInvalidPrivateModal}
        onHide={() => setShowInvalidPrivateModal(false)}
        onClick={() => setShowInvalidPrivateModal(false)}
      />
    </div>
  )
}

EventForm.propTypes = {
  eventId: PropTypes.string,
  isNew: PropTypes.bool,
}
