import { Alert, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import React, { useEffect, useCallback, useState } from 'react'
import TelephoneInput from 'react-phone-number-input/input'

import {
  formatPhoneNumberForDisplay,
  formatPhoneNumberForSave,
} from '../../lib/phone'
import { restoreState } from '../../lib/localstorage'
import { sortObjectsAlphabetically } from '../../lib/sort'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import AvatarImage from '../account/avatar-image'
import BusinessBadge from './business-badge'
import CivWallet from '../../components/CivWallet/civ-wallet'
import CausePicker from '../../components/CausePicker/CausePicker'
import Loading from '../../components/loading'
import SponsorshipAdMessage from './sponsorship-ad-message'
import SponsorshipProgram from './sponsorship-program'
import states from '../../constants/states'
import useApi from '../../hooks/useApi'

export default function BusinessEdit() {
  const { t } = useTranslation()

  const { currentUser, locale, hasBetaFeature } = useCurrentUser()
  const localeKey = locale === 'es' ? 'es_name' : 'en_name'
  const { business_id } = currentUser || {}

  const { getBusiness, getIndustries, updateBusiness } = useApi()

  const [hasBusinessChanged, setHasBusinessChanged] = useState(false)
  const [businessLoading, setBusinessLoading] = useState(false)
  const [business, setBusiness] = useState()
  const [industries, setIndustries] = useState(() =>
    restoreState('industries', []),
  )

  const fetchData = useCallback(async () => {
    setBusinessLoading(true)
    // Get latest user data
    const { data } = await getBusiness(business_id)
    if (data) {
      setBusiness({ ...data, industry_id: data.industry?.id })
    }

    const { data: _industries } = await getIndustries()
    if (_industries) {
      setIndustries(sortObjectsAlphabetically(_industries, localeKey))
    }
    setBusinessLoading(false)
  }, [getBusiness])

  const onSaveBusiness = useCallback(async () => {
    setBusinessLoading(true)
    const params = {
      business: {
        address_line_1: business.address_line_1,
        address_line_2: business.address_line_2,
        category_ids: business.causeIds,
        city: business.city,
        ein: business.ein,
        industry_id: business.industry_id,
        name: business.name,
        phone: business.phone ? formatPhoneNumberForSave(business.phone) : '',
        state: business.state,
        website_url: business.website_url,
        zip: business.zip,
      },
    }
    const response = await updateBusiness(business_id, params)
    if (response.errors) {
      // eslint-disable-next-line no-alert
      alert(response.errors?.full_messages?.[0])
    } else {
      setBusiness({ ...business, ...response.data })
      setHasBusinessChanged(false)
    }
    setBusinessLoading(false)
  }, [business])

  useEffect(() => {
    fetchData()
  }, [])

  const imageBusinessOnChange = async mediaAttachment => {
    const response = await updateBusiness(business_id, {
      media_attachment_id: mediaAttachment.id,
      business: null,
    })
    if (response.errors) {
      // eslint-disable-next-line no-alert
      alert(response.errors?.full_messages?.[0])
    } else {
      setBusiness({
        ...business,
        media_attachment: { ...mediaAttachment },
      })
    }
  }

  return (
    <>
      <Loading show={businessLoading} />
      <br />
      <div className="container">
        <div className="row">
          <h1 className="fs-1">{t('business.edit.title')}</h1>
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="row">
          <h1>{t('business.edit.business_profile')}</h1>
        </div>
        <div className="row">
          <div className="col">
            <AvatarImage
              imageUrl={
                business?.media_attachment?.thumbnail_url ??
                business?.media_attachment?.direct_upload_url ??
                '/images/default-business.png'
              }
              mediaAttachmentType="business"
              onChange={imageBusinessOnChange}
            />
          </div>
          {/* <BusinessBadge
            iconName={'award'}
            iconStyle={'business-badge-icon'}
            label={t('business.edit.badge')}
          /> */}
        </div>
        <div className="row form-row">
          <div className="form-group col-md-6">
            <label htmlFor="name">{t('business.edit.name')}</label>
            <input
              className="form-control"
              id="name"
              name="name"
              type="text"
              value={business?.name}
              onChange={e => {
                setBusiness({ ...business, name: e.target.value })
                setHasBusinessChanged(true)
              }}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="ein">{t('business.edit.ein')}</label>
            <input
              className="form-control"
              id="ein"
              name="ein"
              type="text"
              value={business?.ein}
              onChange={e => {
                setBusiness({ ...business, ein: e.target.value })
                setHasBusinessChanged(true)
              }}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="phone">{t('business.edit.contact_number')}</label>
            <TelephoneInput
              className="form-control"
              country="US"
              initialValueFormat="national"
              international={false}
              onChange={phone => {
                setBusiness({ ...business, phone })
                setHasBusinessChanged(true)
              }}
              placeholder="(123) 456-7890"
              value={formatPhoneNumberForDisplay(business?.phone)}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="form-group col-md-6">
            <label htmlFor="website_url">{t('business.edit.website')}</label>
            <input
              className="form-control"
              id="website_url"
              name="website_url"
              type="text"
              value={business?.website_url}
              onChange={e => {
                setBusiness({
                  ...business,
                  website_url: e.target.value.replace(/(^\w+:|^)\/\//, ''),
                })
                setHasBusinessChanged(true)
              }}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="industry_id">{t('business.edit.industry')}</label>
            <select
              name="industry_id"
              id="industry_id"
              className="form-control"
              onChange={e => {
                setBusiness({ ...business, industry_id: e?.target.value })
                setHasBusinessChanged(true)
              }}
            >
              <option
                selected={
                  !business?.industry_id || business?.industry_id === ''
                }
                value=""
              >
                {t('users.edit.no_gender')}
              </option>
              {industries.map(s => (
                <option
                  selected={business?.industry_id === s.id}
                  value={s.id}
                  key={s.id}
                >
                  {s[localeKey]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row form-row">
          <div className="form-group col-md-6">
            <label htmlFor="address_line_1">{t('business.edit.address')}</label>
            <input
              className="form-control"
              id="address_line_1"
              name="address_line_1"
              type="text"
              value={business?.address_line_1}
              onChange={e => {
                setBusiness({ ...business, address_line_1: e.target.value })
                setHasBusinessChanged(true)
              }}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="address_line_2">
              {t('business.edit.suite_number')}
            </label>
            <input
              className="form-control"
              id="address_line_2"
              name="address_line_2"
              type="text"
              value={business?.address_line_2}
              onChange={e => {
                setBusiness({ ...business, address_line_2: e.target.value })
                setHasBusinessChanged(true)
              }}
            />
          </div>
        </div>
        <div className="row form-row">
          <div className="form-group col-md-4">
            <label htmlFor="city">{t('business.edit.city')}</label>
            <input
              className="form-control"
              id="city"
              name="city"
              type="text"
              value={business?.city}
              onChange={e => {
                setBusiness({ ...business, city: e.target.value })
                setHasBusinessChanged(true)
              }}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="state">{t('business.edit.state')}</label>
            <select
              name="state"
              id="state"
              className="form-control"
              onChange={e => {
                setBusiness({ ...business, state: e?.target.value })
                setHasBusinessChanged(true)
              }}
            >
              <option
                selected={!business?.state || business?.state === ''}
                value=""
              >
                {t('users.edit.no_gender')}
              </option>
              {states.map(s => (
                <option
                  selected={business?.state === s.value}
                  value={s.value}
                  key={s.value}
                >
                  {s.label}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="zip">{t('business.edit.zip')}</label>
            <input
              className="form-control"
              id="zip"
              name="zip"
              type="text"
              value={business?.zip}
              onChange={e => {
                setBusiness({ ...business, zip: e.target.value })
                setHasBusinessChanged(true)
              }}
            />
          </div>
        </div>

        {!businessLoading ? (
          <CausePicker
            onChange={causeIds => {
              setBusiness({ ...business, causeIds })
              setHasBusinessChanged(true)
            }}
            selectedCauseIds={business?.categories?.map(c => c.id) ?? []}
            title={<></>}
          />
        ) : null}

        <input
          className="btn btn-primary"
          id="settings-form-submit"
          onClick={onSaveBusiness}
          type="submit"
          value={t('business.edit.save')}
          disabled={!hasBusinessChanged}
        />
      </div>
      {hasBetaFeature('pf_business') ? (
        <>
          <hr />
          <CivWallet
            data={business}
            setData={setBusiness}
            locale={locale}
            updateData={updateBusiness}
            origin={'business'}
          />
          <hr />
          <SponsorshipProgram businessData={business} />
          <br />
          <SponsorshipAdMessage businessData={business} />
        </>
      ) : null}
      <br />
    </>
  )
}
